//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import service from '@/modules/auth/auth-service';
import browserUpdate from 'browser-update';

browserUpdate({required:{e:0,f:0,o:0,s:0,c:0},insecure:true,api:2020.12 })

// var $buoop = {required:{e:0,f:0,o:0,s:0,c:0},insecure:true,api:2020.12 }
// function $buo_f() { 
//   var e = document.createElement("script")
//   e.src = "//browser-update.org/update.min.js"
//   document.body.appendChild(e)
// };


export default {
  name: 'app',

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },
  async created() {
    const browser = service.get_browser()
    console.log(browser)
    localStorage.setItem('browser', `${browser.name} ${browser.version}`)

    // try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
    // catch(e){window.attachEvent("onload", $buo_f)}

    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then()

    // window.addEventListener('load', this.addToCache(['/']));
    // await this.doInit();
    // firebase.auth().languageCode = localStorage.getItem('language');
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    if (localStorage.getItem('language') == "ar") {
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    // async addToCache(urls) {
    //   
    //   const myCache = await window.caches.open('my-cache');
    //   await myCache.addAll(urls);
    // }, 
  },

  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
    }),
  },
};
