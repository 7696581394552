//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'app-auth-layout',

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
    }),
  },
};
