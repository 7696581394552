import firebase from 'firebase';
import 'firebase/auth';
import config from '@/config';
import 'firebase/app-check';

export default function firebaseInit() {
  const app = firebase.initializeApp(config.firebaseConfig);
  app.auth().languageCode = localStorage.getItem('language');
  // Cashe 
  // firebase.firestore().settings({
  //   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  // });
  // firebase.firestore().enablePersistence();

  const appCheck = firebase.appCheck();

  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  appCheck.activate('6LcDJ_UaAAAAABALR_KfOAv0lhBvrffNt_faz9QL');
  app.auth();
}
