//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getLanguages, getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n'
import { Quasar } from 'quasar'

export default {
  name: 'app-i18n-toggle',

  data() {
    return {
      lang: getLanguageCode(),
    };
  },

  computed: {
    languages() {
      return getLanguages();
    },
    isRTL() {
      return i18n.locale == 'ar'
    },
  },

  methods: {
    doChangeLanguage(language) {
       i18n.locale = language;
      localStorage.setItem('language', language);
      if (language === 'ar') {
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
    },
  },
};
