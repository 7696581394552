import { i18n } from '@/i18n';
import _values from 'lodash/values';

class Roles {
  static get values() {
    return {
      owner: 'owner',
      editor: 'editor',
      viewer: 'viewer',
      auditLogViewer: 'auditLogViewer',
      iamSecurityReviewer: 'iamSecurityReviewer',
      
      entityEditor: 'entityEditor',
      entityViewer: 'entityViewer',

      productEditor: 'productEditor',
      productViewer: 'productViewer',

      sparePartEditor: 'sparePartEditor',
      sparePartViewer: 'sparePartViewer',

      accessoryEditor: 'accessoryEditor',
      accessoryViewer: 'accessoryViewer',

      sellerEditor: 'sellerEditor',
      sellerViewer: 'sellerViewer',

      importerEditor: 'importerEditor',
      importerViewer: 'importerViewer',

      exportEditor: 'exportEditor',
      exportViewer: 'exportViewer',

      complaintEditor: 'complaintEditor',
      complaintViewer: 'complaintViewer',

      // customerEditor: 'customerEditor',
      // customerViewer: 'customerViewer',
      // orderEditor: 'orderEditor',
      // orderViewer: 'orderViewer',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }

  static get selectOptions() {
    return _values(this.values).map((value) => ({
      id: value,
      value: value,
      title: this.descriptionOf(value),
      label: this.labelOf(value),
    }));
  }
}

export default Roles;
