import Layout from '@/modules/layout/components/layout.vue';
import {i18n} from '@/vueI18n'
const contractConfirm = () =>
  import('@/modules/contract/components/contract-confirm.vue');
const contractPrint = () =>
  import('@/modules/contract/components/contract-print.vue');
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'contractConfirm',
        path: '/contract',
        component: contractConfirm,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('home.contract') 
          next();
        },
        meta: {
          auth: true
        },
      }, 
      {
        name: 'contractConfirm',
        path: '/contract/print',
        component: contractPrint,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('home.contract') 
            ' | ' +
            i18n('common.print') 
          next();
        },
        meta: {
          auth: true
        },
      }, 
    ],
  },

];