import { render, staticRenderFns } from "./view-item-page.vue?vue&type=template&id=c01abc6c&scoped=true&"
import script from "./view-item-page.vue?vue&type=script&lang=js&"
export * from "./view-item-page.vue?vue&type=script&lang=js&"
import style0 from "./view-item-page.vue?vue&type=style&index=0&id=c01abc6c&lang=scss&scoped=true&"
import style1 from "./view-item-page.vue?vue&type=style&index=1&id=c01abc6c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c01abc6c",
  null
  
)

export default component.exports
import {QCard,QCardSection,QItemSection,QAvatar,QItemLabel,QIcon,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QItemSection,QAvatar,QItemLabel,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
