import service from '@/modules/auth/auth-service';
// import Message from '@/shared/message/message';
// import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
// import ProgressBar from '@/shared/progress-bar/progress-bar';
import firebase from 'firebase';

// import { IamService } from '@/modules/iam/iam-service'; 

export default {
  namespaced: true,

  state: {
    wholesaller: {},
    authenticationUser: null,
    currentUser: {},
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loading: false,
    saveSellerLoading: false,
    loadingVerify: false,
    firstTime: true,
    confirmationResult: null,
    ananUID: null,
    city: [],
    region: [],
    wholesaler: []
  },

  getters: {
    wholesaller: (state) => state.wholesaller,
    ananUID: (state) => state.ananUID,
    city: (state) => state.city,
    region: (state) => state.region,
    wholesaler: (state) => state.wholesaler,
    confirmationResult: (state) => state.confirmationResult,
    authenticationUser: (state) => state.authenticationUser,
    currentUser: (state) => state.currentUser,
    currentUserEmail: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.email : null,
    currentUserFullName: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.fullName : '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser ?
      getters.currentUser.roles || [] : [],

    completeRegistration: (state, getters) =>
      getters.currentUser ?
      !!getters.currentUser.phoneNumber &&
      !!getters.currentUser.idNumber &&
      !!getters.currentUser.userType &&
      !!getters.currentUser.userCategory : false,

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,
    saveSellerLoading: (state) => !!state.saveSellerLoading,

    loadingVerify: (state) => !!state.loadingVerify,
    firstTime: (state) => !!state.firstTime,
    loadingInit: (state) => !!state.loadingInit,

    loadingEmailConfirmation: (state) =>
      !!state.loadingEmailConfirmation,

    loadingPasswordResetEmail: (state) =>
      !!state.loadingPasswordResetEmail,

    loadingUpdateProfile: (state) =>
      !!state.loadingUpdateProfile,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        return null;
      }

      return getters.currentUser.avatars[0].publicUrl;
    },
  },

  mutations: {
    UPDATE_CURRENT_USER(state, payload) {
      state.currentUser[payload.name] = payload.value
    },
    SET_RETAILERS(state, payload) {
      state.wholesaler = payload
    },
    CLEAR_CITY_AND_REGION(state) {
      state.city = []
      state.region = []
    },
    APPEND_CITY(state, payload) {
      state.city = payload
    },
    APPEND_REGION(state, payload) {
      state.region.push(...payload)
    },
    ADD_ANON_UID(state, payload) {
      state.ananUID = payload
    },
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },
    VERIFY_START(state) {
      state.loadingVerify = true;
    },
    VERIFY_SUCCESS(state) {
      state.loadingVerify = false;
    },
    VERIFY_ERROR(state) {
      state.loadingVerify = false;
    },

    VERIFICATION_CODE_START(state) {
      state.loading = true;
    },
    VERIFICATION_CODE_SUCCESS(state, payload) {
      state.confirmationResult = payload.confirmationResult || null;
      state.loading = false;
    },
    VERIFICATION_CODE_ERROR(state) {
      state.confirmationResult = null;
      state.loading = false;
    },

    AUTH_START(state) {
      state.loading = true;
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loading = false;
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loading = false;
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true;
    },

    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false;
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true;
    },

    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false;
    },

    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false;
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true;
    },

    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false;
    },

    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    AUTH_NOT_FOUND_USER(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    STOP_SPLASH_SCREEN(state) {
      state.firstTime = false;
    },

    SAVE_SELLER_START(state) {
      state.saveSellerLoading = true;
    },
    SAVE_SELLER_SUCCESS(state) {
      state.saveSellerLoading = false;
    },
    SAVE_SELLER_ERROR(state) {
      state.saveSellerLoading = false;
    },
    ADD_WHOLE_SALLER(state, payload) {
      state.wholesaller = payload
    }
  },

  actions: {
    async getSaller({ commit }, id) {
      let doc = (await firebase.firestore().collection('seller').doc(id).get())
      if (doc.exists) {
        let user = doc.data()
        const phone = user.telephone
        commit("ADD_WHOLE_SALLER", user)
        if (phone) {
          doc = (await firebase.firestore().collection('tempSeller').where('phoneNumber', '==', phone).get()).docs
          if (doc.length) {
            user = {
              ...doc[0].data()
            }
            commit("ADD_WHOLE_SALLER", user)
          }
          doc = (await firebase.firestore().collection('user').where('phoneNumber', '==', phone).get()).docs
          if (doc.length) {
            user = {
              ...user,
              ...doc[0].data()
            }
            commit("ADD_WHOLE_SALLER", user)
          }
        }
      } else {
        commit("ADD_WHOLE_SALLER", {})
      }
    },
    async doApprove({ getters, commit, dispatch }, doc) {
      const user = await service.doApprove(getters.currentUser.phoneNumber, doc)
      let old = (await firebase.firestore().collection('seller').where('telephone', '==', getters.currentUser.phoneNumber).get()).docs
      let {
        name,
        address,
        telephone,
        isOnlineStore,
        websiteUrl,
        taxFacilityNumber,
        wholesalerId,
        idNumber,
        countryId,
        cityId,
        regionId,
        managerName,
        managerPhone,
        distributorType
      } = user
      if (old.length) {
        const id = old[0].data().id
        dispatch('seller/form/doUpdate', {
          id,
          values: {
            name,
            address,
            telephone,
            isOnlineStore,
            websiteUrl,
            taxFacilityNumber,
            wholesalerId,
            idNumber,
            countryId,
            cityId,
            regionId,
            manager: {
              name: managerName,
              phoneNumber: managerPhone,
            },
            contract: doc,
            status: old[0].status,
            sellerType: 'distributor',
            distributorType
          }
        }, {
          root: true
        })
      } else {
        dispatch('seller/form/doCreate', {
          name,
          address,
          telephone,
          isOnlineStore,
          websiteUrl,
          taxFacilityNumber,
          wholesalerId,
          idNumber,
          countryId,
          cityId,
          regionId,
          manager: {
            name: managerName,
            phoneNumber: managerPhone,
          },
          contract: doc,
          sellerType: 'distributor',
          status: 'pending',
          distributorType
        }, {
          root: true
        })
      }
      commit('UPDATE_CURRENT_USER', {
        name: 'contractConfirmed',
        value: user.contractConfirmed
      })
      // commit('CURRENT_USER_REFRESH_SUCCESS',{currentUser:user})
      // dispatch('doUpdateCurrentUserInLocalStorage',user)
      // routerAsync().push('/');
    },
    async getWholesalers({ commit }) {
      const arr = await service.getWholesalers()
      commit('SET_RETAILERS', arr)
    },
    signInAnon({ commit }) {
      service.signInAnon(uid => {
        commit("ADD_ANON_UID", uid)
      })
    },
    async isPinCodeValid({ commit }, pinCode) {
      console.log(commit);
      const res = await service.isPinCodeValid(pinCode)
      return res
    },
    getCityAndRegion({ commit }) {
      commit('CLEAR_CITY_AND_REGION')
      service.getCityAndRegion((city) => commit('APPEND_CITY', city), (region) => commit('APPEND_REGION', region))
    },
    async submitSeller({ commit, getters, dispatch }, model) {
      try {
        commit('SAVE_SELLER_START')

        await service.submitSeller(model, getters.currentUser.id)
        let currentUser = await service.fetchMe();
        currentUser = {
          ...currentUser,
          ...model
        }
        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser
        })
        dispatch('doUpdateCurrentUserInLocalStorage', currentUser)
        commit('SAVE_SELLER_SUCCESS')
        routerAsync().push('/');
      } catch (error) {
        commit('SAVE_SELLER_ERROR')
        Errors.handle(error);
      }
    },

  },
};