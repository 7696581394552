import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-seller';
import * as securePin from "secure-pin";
import firebase from 'firebase';
import { storeAsync } from '@/app-module';

export class SellerService {
  static async update(id, data) {
    console.log(JSON.stringify({id,data}));
    
    const response = await graphqlClient.mutate({
      mutation: gql `
      mutation SELLER_UPDATE(
        $id: String!
        $data: SellerInput!
        ) {
          sellerUpdate(id: $id, data: $data) {
            id
          }
        }
        `,
        
        variables: {
          id,
          data,
        },
      });
      
    return response.data.sellerUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation SELLER_DESTROY($ids: [String!]!) {
          sellerDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.sellerDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation SELLER_CREATE($data: SellerInput!) {
          sellerCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.sellerCreate;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation SELLER_IMPORT(
          $data: SellerInput!
          $importHash: String!
        ) {
          sellerImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.sellerImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query SELLER_FIND($id: String!) {
          sellerFind(id: $id) {
            id
            name
            address
            telephone
            manager {
              name
              phoneNumber
            }
            isOnlineStore
            websiteUrl
            taxFacilityNumber
            countryId
            cityId
            regionId
            city
            region
            sellerType
            distributorType
            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.sellerFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query SELLER_LIST(
          $filter: SellerFilterInput
          $orderBy: SellerOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          sellerList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              name
              address
              telephone
              manager {
                name
                phoneNumber
              }
              isOnlineStore
              websiteUrl
              taxFacilityNumber
              countryId
              cityId
              regionId
              city 
              region
              sellerType
              distributorType
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.sellerList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query SELLER_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          sellerAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.sellerAutocomplete;
  }

  static mapCollection(collection) {
    if (collection.empty) {
      return [];
    }

    const list = [];

    collection.forEach((document) => {
      const item = Object.assign({}, document.data(), {
        id: document.id,
      });

      // this.replaceAllTimestampToDate(item);
      list.push(item);
    });

    return list;
  }
  /**
   * Counts the number of Pin Code.
   *
   * @param {Object} filter
   */
  static async pinCodeCount() {
    let chain = firebase.firestore().collection('pinCode');
    return (await chain.get()).size;
  }
  static async pinCodeList() {
    const collection = await firebase.firestore().collection('pinCode').get();
    return this.mapCollection(collection)
    // collection.forEach(async (doc) => {
    //   let city = doc.data();
    //   city['id'] = doc.id;
    //   response.push(city);
    // });
  } 
  static async pinCodeListByTransaction(t) {
    const collection = await t.collection('pinCode').get();
    return this.mapCollection(collection)
  } 

  static async GenerateAndCreatePinCode() {
    try {
      let currentUser = storeAsync().getters['auth/currentUser']
      await firebase.firestore().runTransaction(async (t) => {
        const pinCodeCollection = await this.pinCodeListByTransaction()
        let limit = pinCodeCollection.length
        let pin 

        while(limit < pinCodeCollection.length + 1 ) {
          pin = securePin.generatePinSync(8)
    
          if (pinCodeCollection.length) {
            if(pinCodeCollection.indexOf(pin) === -1) limit += 1
          } else {
            limit += 1
          }
        }  

        let docRef = firebase.firestore().doc(`pinCode/${pin}`) 
        t.set(docRef, {
          id: pin,
          // pinCode: pin,
          createdBy: currentUser.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });
    
       console.log('Transaction success!');
    } catch (e) {
       console.log('Transaction failure:', e);
    }
  }

  static async pinCodeGenerator() {
    try {
      const pinCodeCollection = await this.pinCodeList()
      let limit = pinCodeCollection.length
      let pin 
      
      while(limit < pinCodeCollection.length + 1 ) {
        pin = securePin.generatePinSync(8)
  
        if (pinCodeCollection.length) {
          if(pinCodeCollection.indexOf(pin) === -1) limit += 1
        } else {
          limit += 1
        }
      }  

      return pin
    } catch (e) {
       console.log('Error: ', e);
    }
  }
  static async createPinCode(pinCode) {
    let currentUser = storeAsync().getters['auth/currentUser']

    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`pinCode/${pinCode}`) 
    batch.set(docRef, {
      id: pinCode,
      // pinCode: pin,
      createdBy: currentUser.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    
    await batch.commit()
  }
  static _getRandomInt(min, max) {
    return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
  }

}