const en = {
  messages:{
    pinCodeActivated:'The pin code has been successfully activated',
    // pinCodeIsUsed:'This pin code has been used before',
    pinCodeIsUsed:'This pin code is incorrect',
    noValidForm:'Please fill in all required fields',
    error:'An error has occurred'
  },
  months:{
    allTime:'All time',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  common: {
    undo:'Undo',
    close:'Close',
    created:'Your account has been created successfully',
    attachFile: 'Attach File',
    uploadPicture: 'Upload Picture',
    approve: 'Approve',
    ignore: 'Ignore',
    viewMore: 'View More',
    action: 'Action',
    actions: 'Actions',
    apply: 'Apply',
    publish: 'Publish',
    submit: 'Submit',
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    saveChanges: 'Save Changes',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    delete: 'delete',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    continue: 'Continue',
    next: 'Next',
    previous: 'Previous',
    back: 'Back',
    comingSoon: 'COMING SOON!',
    underConstruction: 'This website still under construction',
    today: 'Today',
    earlier: 'Earlier',
    day: 'Day',
    date: 'Date',
    print: 'Print',
    milady: 'Milady',
    hijri: 'Hijri',
    add: 'Add',
    send: 'Send',
    accept: 'Accept',
    reject: 'Reject',
    yesDelete: 'Yes, delete',
    noThanks: 'No, thanks',
    ex: 'Ex.',
    rePublish: 'Re-Publish',
    mins: 'mins',
    loading: ' Loading...',
    searching: ' Searching...',
    to: 'to',
    congratulations: 'Congratulations!',
    attachedFile: 'Attached File',
    addQualification: 'Add Qualification',
    addCourse: 'Add Course',
    other: 'Other',
    chooseDate: 'Choose a Date',
    employee: ' Employee ',
    employees: ' Employees ',
    addNote: 'Add Note',
    successfully: 'Successfully',
    noteAdded: 'Your Note has been added',
    submitForm: 'Submit Form',
    submitRequest: 'Submit Request',
    history: 'History',
    create: 'Create',
    update: 'Update',
    from: 'From',
    subject: 'Subject',
    reply: 'Reply',
    ticketComplete: 'Ticket complete',
    showDetails: 'Show details',
    createdAt: 'Created At',
    createdBy: 'Created By',
    updatedAt: 'Updated At',
    updatedBy: 'Updated By',
    noResults: 'No results',
    stop: 'Stop',
    readMore: 'Read More',
    readLess: 'Read Less',
    forward: 'Forward',
    done: 'Done',
    features: 'Features',
    deleteThisItem: 'Do you want to delete this item?',
    uploadPhoto: 'Upload photo',
    page: 'Page',
    preview: 'Preview',
    total: 'Total',
    exportationItems: 'Exportation Items',
    items: 'Items',
    createMainData: 'Create main data',
    addDetails: 'Add details',
    addParts: 'Add product parts',
    partsSelected: 'Parts that have been selected',
    chooseYourParts: 'Choose your parts',
    year: 'Year',
    qrCode: 'QR Code',
    url: 'Url',
    finish: 'Finish',
    error: 'Error',
    tryAgain: 'Try again',
    nextItem: 'Next Item',
    startExport: 'Start Export',
    scan: 'Scan',
    scanItems: 'Scan Items',
    waitingSellerCheck: 'Waiting for seller check',
    phoneNumber: ' Phone Number ',
    distributorName: ' Distributor Name ',
    uploadNewPicture: 'Upload new picture',
    barcode: 'Barcode',
    printBarcode: 'Print Barcode',
    chooseOperation: 'Choose The Operation',
    generatePin: 'Generate Pin Code',
    addDistributor: 'Add Distributor',
    updateBrowser: 'Please update your browser to the latest version.',
    notifyUpdateBrowser: 'Your web browser ({0}) is out of date. Update your browser for more security, speed and the best experience on this site.',
    removeField: 'Remove Field',
    addField: 'Add Field',
    submitRequestSuccess: 'Your request has been successfully sent, and the data will be reviewed and contact with you soon',
  },

  app: {
    title: 'Puricom Egypt',
  },

  menu: {
    home: 'Home',
    contract:'Contract',
    dashboard: 'Dashboard',
    database: 'Database',
    products: 'Products',
    spareParts: 'Spare Parts',
    accessories: 'Accessories',
    exportation: 'Exportation',
    importation: 'Importation',
    export: 'Export',
    import: 'Import',
    branch: 'Branches',
    distributor: 'Distributors',
    complaint: 'Complaints',
    users: 'Users',
    admins: 'Administrators',
    clients: 'Clients',
    setting: 'Setting',
    cities: 'Cities',
    regions: 'Regions',
    governorates: 'Governorates',
  },

  empty: {
    products: 'Products list is empty',
    spareParts: 'Spare Parts list is empty',
    accessories: 'Accessories list is empty',
    exportation: 'Exportation list is empty',
    importation: 'Importation list is empty',
    export: 'Export list is empty',
    import: 'Import list is empty',
    importer: 'No imported items', 
    exporter: 'No Exported items',
    branch: 'Branches list is empty',
    distributor: 'Distributors list is empty',
    complaint: 'Complaints list is empty',
    users: 'Users list is empty',
    admins: 'Administrators list is empty',
    clients: 'Clients list is empty',
  },
  entities: {
    product: {
      name: 'product',
      label: 'Products',
      menu: 'Products',
      // emptybranche:'Branches list is empty',
      exporterFileName: 'product_export',
      list: {
        menu: 'Products',
        title: 'Products',
      },
      create: {
        success: 'Product saved successfully',
      },
      update: {
        success: 'Product saved successfully',
      },
      destroy: {
        success: 'Product deleted successfully',
      },
      destroyAll: {
        success: 'Product(s) deleted successfully',
      },
      edit: {
        title: 'Edit Product',
      },
      export: {
        title: 'Please complete the following steps to export your item ',
        iterationNumber: 'Number of item iteration ',
        barcode: 'Barcodes per item',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        itemName: 'Item Name',
        description: 'Description',
        unitPriceRange: 'Unit Price',
        unitPrice: 'Unit Price',
        photos: 'Photos',
        barcode: 'Barcode',
        features: 'Features',
        brand: 'Brand',
        quantity: 'Quantity',
        warrantyPeriod: 'Warranty Period',
        productParts: 'Product Parts',

        productName: 'Product Name',
        createbarcode: 'Create Barcode',
        
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'Add New Product',
      },
      view: {
        title: 'View Product',
      },
      importer: {
        title: 'Import Products',
        fileName: 'product_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    sparePart: {
      name: 'Spare Parts',
      label: 'Spare Parts',
      menu: 'Spare Parts',
      exporterFileName: 'product_export',
      list: {
        menu: 'Spare Parts',
        title: 'Spare Parts',
      },
      create: {
        success: 'Spare Part saved successfully',
      },
      update: {
        success: 'Spare Part saved successfully',
      },
      destroy: {
        success: 'Spare Part deleted successfully',
      },
      destroyAll: {
        success: 'Spare Part(s) deleted successfully',
      },
      edit: {
        title: 'Edit Spare Part',
      },
      export: {
        title: 'Please complete the following steps to export your item ',
        iterationNumber: 'Number of item iteration ',
        barcode: 'Barcodes per item',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        itemName: 'Item Name',
        description: 'Description',
        unitPriceRange: 'Unit Price',
        unitPrice: 'Unit Price',
        photos: 'Photos',
        barcode: 'Barcode',
        features: 'Features',
        brand: 'Brand',
        quantity: 'Quantity',
        warrantyPeriod: 'Warranty Period',

        sparePartName: 'Spare Part Name',
        createbarcode: 'Create Barcode',
        
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'Add New Spare Part',
      },
      view: {
        title: 'View Spare Part',
      },
      importer: {
        title: 'Import Spare Parts',
        fileName: 'product_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    accessory: {
      name: 'Accessories',
      label: 'Accessories',
      menu: 'Accessories',
      exporterFileName: 'product_export',
      list: {
        menu: 'Accessories',
        title: 'Accessories',
      },
      create: {
        success: 'Accessory saved successfully',
      },
      update: {
        success: 'Accessory saved successfully',
      },
      destroy: {
        success: 'Accessory deleted successfully',
      },
      destroyAll: {
        success: 'Accessory(s) deleted successfully',
      },
      edit: {
        title: 'Edit Accessory',
      },
      export: {
        title: 'Please complete the following steps to export your item ',
        iterationNumber: 'Number of item iteration ',
        barcode: 'Barcodes per item',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        itemName: 'Item Name',
        description: 'Description',
        unitPriceRange: 'Unit Price',
        unitPrice: 'Unit Price',
        photos: 'Photos',
        barcode: 'Barcode',
        features: 'Features',
        brand: 'Brand',
        quantity: 'Quantity',
        warrantyPeriod: 'Warranty Period',

        accessoryName: 'Accessory Name',
        createbarcode: 'Create Barcode',
        
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'Add New Accessory',
      },
      view: {
        title: 'View Accessory',
      },
      importer: {
        title: 'Import Accessories',
        fileName: 'product_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    exporter: {
      name: 'Exportation',
      label: 'Exportation',
      menu: 'Exportation',
      title1: 'Please complete the following steps to export your item ',
      iterationNumber: 'Number of item iteration ',
      barcode: 'Barcodes per item',
      chooseItem: 'Choose Selling port',
      chooseFromList: 'Choose from list',
      exportFor: 'Export for',
      pleaseScanItems: 'Please scan items',
      itemType: 'Item type',
      product: 'Product',
      accessory: 'Accessory',
      sparePart: 'Spare part',
      chooseYourItem: 'Choose your item',
      typeNumberHere: 'Type number here',
      exporterFileName: 'file_export',
      sellerNotFound: 'The branch or distributor does not exist Please choose the correct seller outlet',
      scanSerialNumberHere: 'Scan the serial number here',
      pleaseScanSerialNumber : 'Please scan serial number',
      addItemToExportedTable: 'Add an item to the export table',
      itemNotExist: 'This item does not exist',
      list: {
        menu: 'Exportation',
        title: 'Exportation',
      },
      form: {
        title: 'Export New Items',
      },
      item: {
        title: 'Exported Items',
      },
      new: {
        title: 'Export New Items',
      },
      create: {
        success: 'Item saved successfully',
      },
      update: {
        success: 'Item updated successfully',
      },
      destroy: {
        success: 'Item deleted successfully',
      },
      destroyAll: {
        success: 'Item(s) deleted successfully',
      },
      fields: {
        id: 'Id',
        itemId: 'Item ID',
        itemType: 'Item Type',
        status: 'Status',
        qrCode: 'QR Code',
        userId: 'User Id',
        activationDate: 'Activation Date',
        expirationDate: 'Warranty expiry date',
        iterationNumber: 'Number of item iteration',
        
        exportedBy: 'Exported By',
        exportedAt: 'Exported At',

        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'itemType': {
          'product': 'Product',
          'sparePart': 'Spare Part',
          'accessory': 'Accessory',
        },
        'status': {
          'inactive': 'Inactive',
          'active': 'Active',
          'expired': 'Expired',
        },
      },
    },

    importer: {
      name: 'Importation',
      label: 'Importation',
      menu: 'Importation',
      title1: 'Please complete the following steps to import your items',
      title2: 'Please enter the following information in your printer',
      incorrectInputs: 'Incorrect serial number or QR code',
      importedItems: 'Imported items',
      tryNow: ' Try it now',
      printing: 'Printing',
      readyToPrint: 'Ready to Print',
      printingNow: 'Printing now',
      waitingSaveData: 'Waiting to save data',

      numberOfItems: 'Number of items',
      barcode: 'Barcodes per item',
      chooseItem: 'Choose item',
      itemType: 'Item type',
      product: 'Product',
      accessory: 'Accessory',
      sparePart: 'Spare part',
      chooseYourItem: 'Choose your item',
      typeNumberHere: 'Type number here',
      exporterFileName: 'file_export',
      list: {
        menu: 'Importation',
        title: 'Importation',
      },
      form: {
        title: 'Import New Items',
      },
      item: {
        title: 'Items',
      },
      new: {
        title: 'Add New Items',
      },
      create: {
        success: 'Item saved successfully',
      },
      update: {
        success: 'Item updated successfully',
      },
      destroy: {
        success: 'Item deleted successfully',
      },
      destroyAll: {
        success: 'Item(s) deleted successfully',
      },
      fields: {
        id: 'Id',
        itemId: 'Item ID',
        itemType: 'Item Type',
        status: 'Status',
        warrantyStatus: 'Warranty Status',
        qrCode: 'QR Code',
        serialNumber: 'Serial Number',
        userId: 'User Id',
        activationDate: 'Activation Date',
        expirationDate: 'Warranty expiry date',
        
        itemDetails: 'Item Details',
        itemName: 'Item Name',
        brand: 'Brand',
        photo: 'Image',

        iterationNumber: 'Number of item iteration',
        printingCountPerItem: 'Printing Count Per Item',

        exportedBy: 'Exported By',
        exportedAt: 'Exported At',
        
        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created at',
      },
      placeholder: {
        printingCountPerItem: 'Enter the number of times to print each element',
      },
      enumerators: {
        'itemType': {
          'product': 'Product',
          'sparePart': 'Spare Part',
          'accessory': 'Accessory',
        },
        'warrantyStatus': {
          'inactive': 'Inactive',
          'active': 'Active',
          'expired': 'Expired',
        },
        'status': {
          'inStock': 'In Stock',
          'exported': 'Exported',
        }
      },
    },
    
    seller: {
      name: 'Seller',
      label: 'Seller',
      menu: 'Seller',
      represented :'He is represented in this contract :',
      phoneEx: 'Example : 1001234567',
      list: {
        menu: 'Seller',
        title: 'Seller',
      },
      createPin: {
        success: 'Pin Code saved successfully',
      },
      create: {
        success: 'Data saved successfully',
      },
      update: {
        success: 'Data saved successfully',
      },
      destroy: {
        success: 'Data deleted successfully',
      },
      destroyAll: {
        success: 'Data(s) deleted successfully',
      },
      edit: {
        title: 'Edit Data',
        branch: 'Edit Branch',
        distributors: 'Edit Distributor',
      },
      branch: {
        title: 'Branches',
      },
      distributor: {
        title: 'Distributors',
      },
      steps: {
        createMainData: 'Create main data',
        addManagerDetails: 'Add Manager details',
        addLocation: 'Add Location',
      },
      fields: {
        id: 'ID',
        name: 'Full name',
        email: 'Email Address',
        address: 'Address',
        telephone: 'Telephone',
        manager: 'Manager',
        managerName: 'Manager Name',
        managerPhone: 'Manager Phone Number',
        isOnlineStore: 'Is Online Store',
        websiteUrl: 'Website Url',
        taxFacilityNumber: 'Tax Facility Number',
        countryId: 'Country',
        cityId: 'City',
        regionId: 'Region',
        sellerType: 'Type',
        distributorType: 'Distributor Type',
        branchName: 'Branch Name',
        distributorName: 'Distributor Name', 
        retailer:'Retailer',
        idNumber:'ID Number',
        companyName:'Company name',
        area:'Distribution areas',
        wholesaler:'Wholesaler',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdBy: 'Created By',
        updatedBy: 'Updated By',
      },
      placeholder: {
        name: 'Please enter the full name',
        email: 'Please enter the email address',
        address: 'Please enter the Address',
        telephone: 'Please enter the Telephone',
        manager: 'Please enter the Manager',
        managerName: 'Please enter the Manager Name',
        managerPhone: 'Please enter the Manager Phone Number',
        isOnlineStore: 'Please enter the Is Online Store',
        websiteUrl: 'Please enter the Website Url',
        taxFacilityNumber: 'Please enter the Tax Facility Number',
        countryId: 'Please enter the Country',
        cityId: 'Please choose the City',
        city: 'Please enter the City',
        regionId: 'Please choose the Region',
        sellerType: 'Please enter the Type',
        distributorType: 'Please enter the Distributor Type',
        branchName: 'Please enter the Branch Name',
        distributorName: 'Please enter the Distributor Name', 
        retailer:'Please enter the Retailer',
        wholesaler:'Please enter the Wholesaler',
        idNumber:'Please enter the ID Number',
        companyName:'Please enter company name',
        area:'Enter distribution area name',
        // area:'Enter distribution area name then press enter',
      },
      error: {
        name: 'Name is required',
        address: 'Address is required',
        telephone: 'Telephone is required',
        manager: 'Manager is required',
        managerName: 'Manager Name is required',
        managerPhone: 'Manager Phone Number is required',
        isOnlineStore: 'Is Online Store is required',
        websiteUrl: 'Website Url is required',
        taxFacilityNumber: 'Tax Facility Number is required',
        idNumber:'ID Number is required',
        countryId: 'Country is required',
        cityId: 'City is required',
        regionId: 'Region is required',
        sellerType: 'Type is required',
        distributorType: 'Distributor Type is required',
        branchName: 'Branch Name is required',
        distributorName: 'Distributor Name is required',
        retailer:'Retailer is required',
        companyName:'Company name is required',
        area:'Distribution areas are required',
      },
      enumerators: {
        'sellerType': {
          "branch": 'Branch',
          "distributor": 'Distributor',
        },
        'distributorType': {
          "retailer": 'Retailer',
          "wholesaler": 'Wholesaler',
        },
      },
      new: {
        title: 'Add New Seller',
        branch: 'Add New Branch',
        distributors: 'Add New Distributor',
      },
      view: {
        title: 'View Seller',
        branch: 'View Branch',
        distributors: 'View Distributor',
      },
      importer: {
        title: 'Import Sellers',
        fileName: 'seller_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    city: {
      name: 'Cities',
      label: 'Cities',
      menu: 'Cities',
      list: {
        menu: 'Cities',
        title: 'Cities',
      },
      new: {
        title: 'Add New City',
      },
      create: {
        success: 'City saved successfully',
      },
      update: {
        success: 'City updated successfully',
      },
      destroy: {
        success: 'City deleted successfully',
      },
      destroyAll: {
        success: 'Cities deleted successfully',
      },
      fields: {
        id: 'ID',
        name: 'City Name',
        nameEn: 'City Name English',
        nameAr: 'City Name Arabic',
        regions: 'Regions',

        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created at',
      },
      placeholder: {
        nameEn: 'Enter the city name in English',
        nameAr: 'Enter the city name in Arabic',
      },
    },

    region: {
      name: 'Regions',
      label: 'Regions',
      menu: 'Regions',
      list: {
        menu: 'Regions',
        title: 'Regions',
      },
      new: {
        title: 'Add New Region',
      },
      create: {
        success: 'Region saved successfully',
      },
      update: {
        success: 'Region updated successfully',
      },
      destroy: {
        success: 'Region deleted successfully',
      },
      destroyAll: {
        success: 'Regions deleted successfully',
      },
      fields: {
        id: 'ID',
        name: 'Region Name',
        nameEn: 'Region Name English',
        nameAr: 'Region Name Arabic',
        regions: 'Regions',
        city: 'City',

        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created at',
      },
      placeholder: {
        nameEn: 'Enter the region name in English',
        nameAr: 'Enter the region name in Arabic',
        city: 'Select Your City',
      },
    },

    complaint: {
      name: 'complaint',
      createNewComplaint: 'create new complaint',
      placeholder: 'search by complaint number',
      noComplaint:'No complaint here',
      messagePlaceholder:'Type your message here'
    }
  },

  auth: {
    error: {
      emailRequired: 'Email is required',
      phoneNumberRequired: 'Phone number is required',
      invalidPhoneNumber:'Invalid phone number',
      passwordRequired: 'Password is required',
      confirmRequired: 'Confirm Password is required',
      confirmNotMatched: 'Confirm Password does not matched',
      phoneRequired: 'Phone Number is required',
      codeRequired: 'Verification Code is required',
      idNumberRequired: 'Id Number is required',
      PhoneMustBe_11_Digits: 'The Phone number must be 9 digits',
      firstNameRequired: 'First Name is required',
      lastNameRequired: 'Last Name is required',
      idNumberShouldOnlyBeNumbers: 'The ID number should only be numbers',
      verificationCodeShouldOnlyBeNumbers: 'The Verification Code should only be numbers',

      nameRequired:'Name  is required',
      telephoneRequired:'Telephone  is required',
      taxRequired:'Tax Facility Number  is required',
      retailerRequired:'Retailer is required',
    },
    splash: {
      title: '',
      subTitle: '',
      hint: '',
      letsGetStarted: 'Let’s get started',
    },
    profile: {
      title: 'My Profile',
      edit: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    fields: {
      email: 'Email address',
      confirm: 'Confirm password',
      phoneNumber: 'Phone number',
      password: 'Password',
      pinCode:'Pin code',

      name:'name',
      telephone:'Telephone',
      tax:'Tax Facility Number',
      sellerType:'Seller type',
      retailer:'Choose Retailer',
      isOnline:'Is online store'

    },
    placeholder: {
      email: 'Enter email address here',
      confirm: 'Enter confirm password here',
      phoneNumber: 'Phone number',
      password: 'Enter password here',

      name:'Enter your name here',
      telephone:'Enter your telephone here',
      tax:'Enter your telephone here',
      retailer:'Choose your retailer',
    },
    sellerEnum:{
      wholesaler:'Wholesaler',
      retailer:'Retailer'
    },
    resend:'Resend the code',
    signature:'Signature',
    yourContract:'Your contract',
    contractAcknowledgment:'I, {0}, acknowledge that I have read and agree to the terms and conditions mentioned in the contract',
    contractPlaceholder:'please read carefully the contract and choose between confirm or ignore the contract',
    createMainData:'Create main data',
    addLocation:'Add Location',
    addManagerDetails:'Add Manager details',
    ignore:'Ignore',
    continue:'Continue',
    welcomeTo: 'Welcome to ',
    developedByRunProf: 'Developed by RunProf',
    puricomEgypt: 'Puricom Egypt ',
    sellersDashboard:'Authorized distributors control panel',
    message: 'Water treatment professional system',
    site:'www.puricomegypt.com',
    verifyAccount: 'Verify your account',
    createdSuccessfully: 'Successfully created your new account',
    createdNewAccount: 'Created your new account',
    registrationSteps: 'Registration Steps',
    DoNotHaveAccount: 'Don’t have account? ',
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forget your password?',
    forgotPasswordTitle: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    login: 'Log in',
    

    alreadyHaveAnAccount: ' Already have an account? ',
    signinWithAnotherAccount: 'Sign in with another account',
    verify:{
      verify:'Verify your account',
      message:'Please enter the code that we sent to you'
    },
    registration:{
      registration:'Registration',
      message:'Please enter your pin code to complete your registration',
      message2:'Please enter your data to complete the contract',
      enterYourPhoneNumper:'Enter your phone number',
      enterYourPhoneNumperPlaceholder:'Please enter your phone number'
    },
    signUp: {
      sign: 'Sign ',
      up: 'up',
      message: `Please enter your email address and password to create new account`,
    },
    logIn: {
      log: 'log',
      in: 'in',
      message: `Please enter your email address and password to log in your account`,
    },
    emailUnverified: {
      title: 'Confirm email address',
      message: `Please confirm your email at <strong style="color: #00B2E3;">{0}</strong> to continue.`,
      submit: `Resend Verification Email`,
      verify: 'Verify email address',
      confirm: 'Confirm ',
      emailAddress: 'Email Address',
    },
    forgetPassword: {
      title: 'Forget your password?',
      message: `Enter your email address you are using for your account, and we will send you a password reset link`,
      submit: `Request Reset link`,
      placeholder: 'Enter your email address here',
      forget: 'Forget ',
      password: 'password',
      resendLink: 'Resend link',
    },
    emptyPermissions: {
      title: 'Pending request',
      account: 'Account ',
      permission: 'ermission',
      message: `You have no permissions yet. Wait for the admin to grant you privileges`,
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    customerEditor: {
      label: 'Customer Editor',
      description: 'Edit access to Customers',
    },
    customerViewer: {
      label: 'Customer Viewer',
      description: 'View access to Customers',
    },
    productEditor: {
      label: 'Product Editor',
      description: 'Edit access to Products',
    },
    productViewer: {
      label: 'Product Viewer',
      description: 'View access to Products',
    },
    orderEditor: {
      label: 'Order Editor',
      description: 'Edit access to Orders',
    },
    orderViewer: {
      label: 'Order Viewer',
      description: 'View access to Orders',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'Users',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this phone number already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
      phone: 'Phone number is invalid',
      idNumber: 'ID number is invalid',
      taxFacilityNumber: 'Tax facility number is invalid'
    },
  },
  
  home: {
    menu: 'Home',
    contract:'Contract',
    message: `This page uses fake data for demonstration purposes only. You can edit it at seller-frontend/src/modules/home/components/home-page.vue.`,
    confirmed:'Your request has been successfully accepted',
    pending:'Your request is under review',
    renewalPending:'Your renewal request is under review',
    ending:'You have {0} days left until the contract expires',
    ended:'Your contract has expired, please contact us to renewal',
    canceled: 'Your contract has been canceled by the company',
    banner:'You are now a distributor for Puricom Egypt',
    bannerRetailer:'You are now a distributor for Puricom Egypt',
    newFeaturesAreComing:'New Features are coming',
    printTheContract: 'Print the contract',
    renew:'Renew',
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },

  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success: 'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
      barcodeTitle: 'Barcode Title',
    },
    palceholder: {
      barcodeTitle: 'Type Barcode Title Here',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      'astronaut-blue': 'Astronaut Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },

  errors: {
    required: 'This field is required',
    minValue: 'The minimum value is {0}',
    maxValue: 'The maximum value is {0}',
    maxCharCount: 'Please use maximum {0} characters',
    typeRequired: 'Type is Required',
    languageRequired: 'Language is Required',
    destinationRequired: 'Destination is Required',
    purposeRequired: 'Purpose is Required',
    workAtRequired: 'Work At is Required',
    wifeNameRequired: 'Wife Name is Required',
    dateRequired: 'Date is Required',
    timeRequired: 'Time is Required',
    hospitalNameRequired: 'Hospital Name is Required',
    nameRequired: 'Name is Required',
    workPositionRequired: 'Work Position is Required',
    signatureRequired: 'Signature is Required',
    birthDateRequired: 'Birth Data is Required',
    relativeRelationRequired: 'Relative Relation is Required',
    placeOfBusinessRequired: 'Place of Business is Required',
    placeOfResidenceRequired: 'Place of Residence is Required',
    buildingRequired: 'Building is Required',
    floorRequired: 'Floor is Required',
    accountTypeRequired: 'Account Type is Required',
    managerTypeRequired: 'Manager Type is Required',
    invalidPhoneNumber: 'Invalid phone number',
    invalidNoteField: 'Invalid Note field',
    invalidReasonOfPermission: 'Invalid reason of permission field ',
    departmentRequired: 'Department is Required',
    subDepartmentRequired: 'Sub Department is Required',
    jobTitleRequired: 'Job Title is Required',
    employeeRequired: 'Employee is Required',
    employeesRequired: 'Employees is Required',

    recordIsFound: 'This record is already exist with the same barcode',

    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },

  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({
        path,
        type,
        value,
        originalValue
      }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },

  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    attach: 'Attach File',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
    picture: 'Upload picture'
  },

  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential': 'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use': 'Credentials are already in use',
    'auth/invalid-phone-number': 'Invalid phone number (Too Short)',
    'auth/invalid-verification-code': 'Invalid verification code',
    toManyTries:'You have been banned due to too many attempts'
  },
};

export default en;