// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCl7ZTVGWwgdzouyi1JynhIu4xS2RAuFug",
  authDomain: "puricom-beebe.firebaseapp.com",
  databaseURL: "https://puricom-beebe.firebaseio.com",
  projectId: "puricom-beebe",
  storageBucket: "puricom-beebe.appspot.com",
  messagingSenderId: "781354733996",
  appId: "1:781354733996:web:a692536ee54ace33016a09",
  measurementId: "G-QZX99KF8XD"
};

// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;

const backendUrlAuth = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/auth`

const backendUrlIam = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/iam`

const backendUrlProduct = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/product`

const backendUrlAccessory = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/accessory`

const backendUrlSparePart = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/sparePart`

const backendUrlSeller = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/seller`

const backendUrlExport = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/export`

const backendUrlImporter = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/importer`

const backendUrlComplaint = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/complaint`

// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
  backendUrlAuth,
  backendUrlIam,
  backendUrlProduct,
  backendUrlAccessory,
  backendUrlSparePart,
  backendUrlSeller,
  backendUrlExport,
  backendUrlComplaint,
  backendUrlImporter,
};
