import Layout from '@/modules/layout/components/layout.vue';
// import AuthLayout from '@/modules/auth/components/auth-layout.vue';
import AuthLayoutWhite from '@/modules/auth/components/auth-layout-white.vue';
import {i18n} from '@/vueI18n'
const SplashScreenPage = () =>
  import('@/modules/auth/components/splash-screen-page.vue');
const SigninPage = () =>
  import('@/modules/auth/components/signin-page.vue');
const SignupPage = () =>
  import('@/modules/auth/components/signup-page.vue');
const ForgotPasswordPage = () =>
  import('@/modules/auth/components/forgot-password-page.vue');
const EmailUnverifiedPage = () =>
  import('@/modules/auth/components/email-unverified-page.vue');
const EmptyPermissionsPage = () =>
  import('@/modules/auth/components/empty-permissions-page.vue');
const ProfileFormPage = () =>
  import('@/modules/auth/components/profile-form-page.vue');
// const contractConfirm = () =>
//   import('@/modules/auth/components/contract-confirm.vue');

const FormsPage = () =>
  import('@/modules/auth/components/forms-page.vue');

export default [
  {
    name: 'splashScreen',
    path: '/auth',
    component: SplashScreenPage,
    beforeEnter: (to, from, next) => {
      document.title =
        i18n('app.title') +
        ' | ' +
        i18n('entities.seller.name') 
      next();
    },
    meta: {
      unauth: true
    },
  },
  {
    name: 'forms',
    path: '/forms',
    component: FormsPage,
    beforeEnter: (to, from, next) => {
      document.title =
        i18n('app.title') +
        ' | ' +
        i18n('entities.seller.name') 
      next();
    },
    meta: {
      unauth: true
    },
  },
  {
    name: '',
    path: '',
    component: AuthLayoutWhite,
    children: [
      {
        name: 'signin',
        path: '/auth/signin',
        component: SigninPage,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('auth.signin') 
          next();
        },
        meta: {
          unauth: true
        },
      },
      {
        name: 'signup',
        path: '/auth/signup',
        component: SignupPage,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('auth.signup') 
          next();
        },
        meta: {
          unauth: true
        },
      },
      {
        name: 'signup',
        path: '/auth/details',
        component: SignupPage,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('auth.signup') 
          next();
        },
        meta: {
          auth: true
        },
      },
      {
        name: 'forgotPassword',
        path: '/auth/forgot-password',
        component: ForgotPasswordPage,
        
        meta: {
          unauth: true
        },
      },
      {
        name: 'emailUnverified',
        path: '/auth/email-unverified',
        component: EmailUnverifiedPage,
        meta: {
          auth: true,
          emailAlreadyVerified: true
        },
      },
      {
        name: 'emptyPermissions',
        path: '/auth/empty-permissions',
        component: EmptyPermissionsPage,
        meta: {
          auth: true,
          notEmptyPermissions: true
        },
      },
    ],
  },
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'editProfile',
        path: '/auth/edit-profile',
        component: ProfileFormPage,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('auth.profile.edit') 
          next();
        },
        meta: { auth: true },
      }, 
    ],
  },
  // {
  //   name: '',
  //   path: '',
  //   component: Layout,
  //   meta: { auth: true },
  //   children: [
  //     {
  //       name: 'contractConfirm',
  //       path: '/auth/contract',
  //       component: contractConfirm,
  //       meta: {
  //         auth: true
  //       },
  //     }, 
  //   ],
  // },

];