import Layout from '@/modules/layout/components/layout';

// const contractPage = () =>
//   import('@/modules/PDF/components/contract.vue');

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      // {
      //   name: 'home',
      //   path: '/contract',
      //   component: contractPage,
      //   exact: true,
      //   meta: { auth: true },
      // },
    ],
  },
];
