//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import firebase from 'firebase'
import 'firebase/firestore'
import { routerAsync } from '@/app-module';
import moment from 'moment'
import Message from '@/shared/message/message';
import Banner from '@/modules/layout/components/banner.vue';


export default {
  name: 'app-layout',

  components: {
    [Banner.name]: Banner,
  },

  created(){
    // Listening on Seller Is Modified
    firebase.firestore().collection('seller').where('telephone', '==', firebase.auth().currentUser.phoneNumber).onSnapshot(async snap => {
      console.log(`new seller snap with size ${snap.size}`);
      this.doRefreshCurrentUser()

      snap.docChanges().forEach(change => {
        if (change.type === 'modified' && change.doc.data().status == 'confirmed') {
          this.pushNotifyIsConfirmedReq();
        }
      });
      if(this.currentUser && this.currentUser.distributorType === 'wholesaler' && this.currentUser.wholesalerId){
        let data = (await firebase.firestore().collection('seller').doc(this.currentUser.wholesalerId).get()).data()
        this.wholesalerName = data.name
      }
    })

    // Listening on CurrentUser Is Modified
    firebase.firestore().collection('user').where('id', '==', this.currentUser.id).onSnapshot(async snap => {
      // console.log(`new seller snap with size ${snap.size}`);
      snap.docChanges().forEach(change => {
        if (change.type === 'modified') {
          this.doRefreshCurrentUser()
        }
      });
    })
  },

  data() {
    return {
      drawer: false,
      left: false,
      miniState: false,
      wholesalerName: '',
      closed: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
    }),
    daysLeft(){
      return moment(this.currentUser.contractEndDate,'DD/MM/YYYY').diff(moment(),'days')
    },
    asideWidth() {
      if (!this.isMobile ) {
        return 220;
      }
      return 200;
    },
    currentBrowser() {
      return localStorage.getItem('browser')
    }
  },
  methods: {
    ...mapActions({
      initChat: 'initChat',
      setOtherUserID: 'setOtherUserID',
      doLogoutChat: 'auth/doLogoutChat',
      doRefreshCurrentUser: 'auth/doRefreshCurrentUser'
    }),
    goPrint(){
      return routerAsync().push('/contract/print');
    },
    async goRenew(){
      
      const docs = (await firebase.firestore().collection('seller').where('telephone','==',this.currentUser.phoneNumber).get()).docs
      if(docs && docs.length){
        docs[0].ref.update({status:'renewal'})
      }
    },
    async wholesaler(){
      if(this.currentUser && this.currentUser.distributorType === 'wholesaler' && this.currentUser.wholesalerId){
        let data = firebase.firestore().collection('seller').doc(this.currentUser.wholesalerId).get()
        return data.data()
      }
      return ''
    },
    pushNotifyIsConfirmedReq() {
      Message.success(
        this.i18n('home.confirmed'),
      );
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    drawerClick(e) {
      // if in "mini" state and user
      // click on drawer, we switch it to "normal" mode
      if (this.miniState) {
        this.miniState = false;
        // notice we have registered an event with capture flag;
        // we need to stop further propagation as this click is
        // intended for switching drawer to "normal" mode only
        e.stopPropagation();
      }
    },
  },
};
