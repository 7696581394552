//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import {i18n} from '@/i18n'
import firebase from 'firebase'
import 'firebase/firestore'
import { routerAsync } from '@/app-module';
import moment from 'moment'
import Message from '@/shared/message/message';

export default {
  name: 'app-banner',
  props: ['wholesalerName'],

  data() {
    return {
      // wholesalerName: '',
      closed: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
    }),
    daysLeft(){
      return moment(this.currentUser.contractEndDate,'DD/MM/YYYY').diff(moment(),'days')
    },
  },

  methods: {
    ...mapActions({
      doSignout: 'auth/doSignout',
      doRefreshCurrentUser: 'auth/doRefreshCurrentUser',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    i18nLabel(label,args){
      return i18n(label,args)
    },
    pushNotifyIsConfirmedReq() {
      Message.success(
        i18n('home.confirmed'),
      );
    },
    goPrint(){
      return routerAsync().push('/contract/print');
      // return this.$router.push('/contract/print');
    },
    async goRenew(){
      
      const docs = (await firebase.firestore().collection('seller').where('telephone','==',this.currentUser.phoneNumber).get()).docs
      if(docs && docs.length){
        docs[0].ref.update({status:'renewal'})
      }
    },
    async wholesaler(){
      if(this.currentUser && this.currentUser.distributorType === 'wholesaler' && this.currentUser.wholesalerId){
        let data = firebase.firestore().collection('seller').doc(this.currentUser.wholesalerId).get()
        return data.data()
      }
      return ''
    },
  },
};
