//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapGetters } from 'vuex';
// import { i18n } from '@/i18n';

export default {
  name: 'app-delete-item-page',
  props: ['id', 'loading', 'storePath'],

  data() {
    return {

    };
  },
  computed: {
    // ...mapGetters({
    //   loading: `${this.storePath}/loading`,
    // })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      await this.$emit('submit', this.id)
    },
  },
};
