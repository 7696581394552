const ar = {
  messages:{
    pinCodeActivated:'تم تفعيل الرقم السري بنجاح',
    // pinCodeIsUsed:'هذا الرقم تم استخدامه من قبل',
    pinCodeIsUsed:'هذا الرقم غير صحيح',
    noValidForm:'من فضلك قم ملء جميع الحقول المطلوبة',
    error:'لقد حدث خطأ'
  },
  months:{
    allTime:'كل الوقت',
    January: 'يناير',
    February: 'فبراير',
    March: 'مارس',
    April: 'أبريل',
    May: 'مايو',
    June: 'يونيو',
    July: 'يوليو',
    August: 'أغسطس',
    September: 'سبتمبر',
    October: 'أكتوبر',
    November: 'نوفمبر',
    December: 'ديسمبر',
  },
  common: {
    undo:'تراجع',
    close:'إغلاق',
    created:'تم إنشاء حسابك بنجاح',
    attachFile: 'إرفاق ملف',
    uploadPicture: 'تحميل الصورة',
    approve: 'اعتماد',
    ignore: 'تجاهل',
    viewMore: 'عرض المزيد',
    action: 'العمليات',
    actions: 'العمليات',
    apply: 'تطبيق',
    publish: 'نشر',
    submit: 'إرسال',
    or: 'أو',
    cancel: 'إلغاء',
    reset: 'إعادة تعيين',
    save: 'حفظ',
    saveChanges: 'حفظ التغيرات',
    search: 'بحث',
    edit: 'تعديل',
    remove: 'حذف',
    new: 'جديد',
    export: 'تصدير إلي أكسيل',
    noDataToExport: 'لا توجد بيانات للتصدير',
    import: 'استيراد',
    discard: 'تجاهل',
    delete: 'حذف',
    yes: 'نعم',
    no: 'لا',
    pause: 'إيقاف',
    areYouSure: 'هل أنت متأكد؟',
    view: 'عرض',
    destroy: 'مسح',
    destroyAll: 'حذف الكل',
    mustSelectARow: 'يجب أختيار صف',
    confirm: 'تأكيد',
    continue: 'التالي',
    next: 'التالي',
    previous: 'السابق',
    back: 'رجوع',
    comingSoon: ' قريبـــــا',
    underConstruction: 'هذا الموقع لا يزال قيد الإنشاء',
    today: 'اليوم',
    earlier: 'سابقاً',
    day: 'اليوم',
    date: 'التاريخ',
    print: 'طباعة',
    milady: 'ميلادي',
    hijri: 'هجري',
    add: 'أضف',
    send: 'أرسال',
    accept: 'قبول',
    reject: 'رفض',
    yesDelete: 'نعم، أحذف',
    noThanks: 'لا، شكراً',
    ex: 'مثال',
    rePublish: 'إعادة النشر',
    mins: 'دقيقة',
    loading: ' جاري التحميل... ',
    searching: ' جاري البحث... ',
    to: 'إلي',
    congratulations: 'مبــروك!',
    attachedFile: 'الملف المرفق',
    addQualification: 'إضافة مؤهل',
    addCourse: 'إضافة دورة',
    other: 'آخرى',
    chooseDate: 'اختر التاريخ',
    employee: ' موظف ',
    employees: ' موظفين ',
    addNote: 'اضف ملاحظة',
    successfully: 'بنجاح',
    noteAdded: 'تمت إضافة ملاحظتك',
    submitForm: 'إرسال نموذج',
    submitRequest: 'إرسال طلب',
    history: 'السجل',
    create: 'إنشاء',
    update: 'تحديث',
    from: 'من',
    subject: 'الموضوع',
    reply: 'رد',
    ticketComplete: 'اكتملت التذكرة',
    showDetails: 'عرض التفاصيل',
    createdAt: 'أنشئت في',
    createdBy: 'تم انشائها بواسطة',
    updatedAt: 'تم التحديث في',
    updatedBy: 'تم التحديث بواسطة',
    noResults: 'لا توجد نتائج',
    stop: 'توقف',
    readMore: 'قراءة المزيد',
    readLess: 'قراءة أقل',
    forward: 'إعادة توجيه',
    done: 'تم',
    features: 'المميزات',
    deleteThisItem: 'هل تريد حذف هذا العنصر؟',
    uploadPhoto: 'حمل الصورة',
    page: 'صفحة',
    preview: 'معاينة',
    total: 'الإجمالي',
    exportationItems: 'العناصر المصدرة',
    items: 'العناصر',
    createMainData: 'إنشاء البيانات الأساسية',
    addDetails: 'إضافة التفاصيل',
    addParts: 'إضافة أجزاء المنتج',
    partsSelected: 'الأجزاء التي تم اختيارها',
    chooseYourParts: 'إختر القطع',
    year: 'سنة',
    qrCode: 'رمز الاستجابة السريعة',
    url: 'Url',
    finish: 'إنهاء',
    error: 'خطأ',
    tryAgain: 'حاول مرة أخري',
    nextItem: 'العنصر التالي',
    startExport: 'ابدأ التصدير',
    scanItems: 'فحص العنصر',
    scan: 'فحص',
    waitingSellerCheck: 'في انتظار فحص البائع',
    phoneNumber: ' رقم التليفون ',
    distributorName: ' اسم الموزع ',
    uploadNewPicture: 'تحميل صورة جديدة',
    barcode: 'الباركود',
    printBarcode: 'طباعة الباركود',
    chooseOperation: 'اختر العملية',
    generatePin: 'إنشاء رمز PIN',
    addDistributor: 'إضافة موزع',
    updateBrowser: 'يرجى تحديث المتصفح الخاص بك إلى أحدث إصدار.',
    notifyUpdateBrowser: 'متصفح الويب الخاص بك ({0}) قديم. قم بتحديث المستعرض الخاص بك لمزيد من الأمان والسرعة وأفضل تجربة على هذا الموقع.',
    removeField: 'إزالة الحقل',
    addField: 'إضافة حقل',
    submitRequestSuccess: 'تم إرسال طلبك بنجاح وسيتم مراجعة البيانات والاتصال بك قريبا',

  },

  app: {
    title: 'بيوريكم مصر',
  },

  menu: {
    home: 'الرئيسية',
    contract:'العقد',
    dashboard: 'لوحة التحكم',
    database: 'قاعدةالبيانات',
    products: 'المنتجات',
    spareParts: 'قطع الغيار',
    accessories: 'الإكسسوارات',
    exportation: 'التصدير',
    importation: 'الإستيراد',
    export: 'تصدير',
    import: 'إستيراد',
    branch: 'الفروع',
    distributor: 'الموزعين',
    complaint: 'الشكاوى',
    users: 'المستخدمين',
    admins: 'المسؤولين',
    clients: 'العملاء',
    setting: 'الإعدادات',
    governorates: 'المحافظات',
    cities: 'المحافظات / المدن',
    regions: 'المناطق',
  },
  
  empty: {
    products: 'قائمة المنتجات فارغة',
    spareParts: 'قائمة قطع الغيار فارغة',
    accessories: 'قائمة الاكسسوارات فارغة',
    exportation: 'قائمة التصدير فارغة',
    importation: 'قائمة الاستيراد فارغة',
    export: 'قائمة التصدير فارغة',
    import: 'قائمة الاستيراد فارغة',
    importer: 'لا توجد عناصر مستوردة', 
    exporter: 'لا توجد عناصر مُصدرة',
    branch: 'قائمة الفروع فارغة',
    distributor: 'قائمة الموزعين فارغة',
    complaint: 'قائمة الشكاوى فارغة',
    users: 'قائمة المستخدمين فارغة',
    admins: 'قائمة المسؤولين فارغة',
    clients: 'قائمة العملاء فارغة',
  },

  entities: {
    product: {
      name: 'المنتجات',
      label: 'المنتجات',
      menu: 'المنتجات',
      emptybranche:'قائمة الفروع فارغة',
      list: {
        menu: 'المنتجات',
        title: 'المنتجات',
      },
      create: {
        success: 'تم حفظ المنتج بنجاح',
      },
      update: {
        success: 'تم تحديث المنتج بنجاح',
      },
      destroy: {
        success: 'تم حذف المنتج بنجاح',
      },
      destroyAll: {
        success: 'تم حذف المنتجات بنجاح',
      },
      edit: {
        title: 'تعديل المنتج',
      },
      export: {
        title: 'يرجى إكمال الخطوات التالية لتصدير العنصر الخاص بك ',
        iterationNumber: 'عدد المنتجات المصدرة من هذا العنصر',
        barcode: 'الباركود لكل بند',
      },
      fields: {
        id: 'Id',
        'name': 'الإسم',
        itemName: 'إسم العنصر',
        description: 'الوصف',
        photos: 'الصورة',
        barcode: 'الباركود',
        features: 'المواصفات',
        brand: 'الماركة',
        quantity: 'الكمية',
        warrantyPeriod: 'مدة الضمان',
        productParts: 'أجزاء المنتج',

        productName: 'إسم المنتج',
        createbarcode: 'إنشاء باركود',
        
        createdAt: 'إنشاء في',
        updatedAt: 'تحديث في',
        createdAtRange: 'إنشاء في',
      },
      enumerators: {

      },
      new: {
        title: 'إضافة منتج جديد',
      },
      view: {
        title: 'عرض المنتج',
      },
    },

    sparePart: {
      name: 'قطع الغيار',
      label: 'قطع الغيار',
      menu: 'قطع الغيار',
      list: {
        menu: 'قطع الغيار',
        title: 'قطع الغيار',
      },
      create: {
        success: 'تم حفظ قطعة الغيار بنجاح',
      },
      update: {
        success: 'تم تحديث قطعة الغيار بنجاح',
      },
      destroy: {
        success: 'تم حذف قطعة الغيار بنجاح',
      },
      destroyAll: {
        success: 'تم حذف قطع الغيار بنجاح',
      },
      edit: {
        title: 'تعديل قطعة الغيار',
      },
      export: {
        title: 'يرجى إكمال الخطوات التالية لتصدير العنصر الخاص بك ',
        iterationNumber: 'عدد المنتجات المصدرة من هذا العنصر',
        barcode: 'الباركود لكل بند',
      },
      fields: {
        id: 'Id',
        'name': 'الإسم',
        itemName: 'إسم العنصر',
        description: 'الوصف',
        photos: 'الصورة',
        barcode: 'الباركود',
        features: 'المواصفات',
        brand: 'الماركة',
        quantity: 'الكمية',
        warrantyPeriod: 'مدة الضمان',

        sparePartName: 'إسم قطعة الغيار',
        createbarcode: 'إنشاء باركود',
        
        createdAt: 'إنشاء في',
        updatedAt: 'تحديث في',
        createdAtRange: 'إنشاء في',
      },
      enumerators: {

      },
      new: {
        title: 'إضافة قطعة غيار جديدة',
      },
      view: {
        title: 'عرض قطعة الغيار',
      },
    },

    accessory: {
      name: 'الإكسسوارات',
      label: 'الإكسسوارات',
      menu: 'الإكسسوارات',
      list: {
        menu: 'الإكسسوارات',
        title: 'الإكسسوارات',
      },
      create: {
        success: 'تم حفظ الإكسسوار بنجاح',
      },
      update: {
        success: 'تم تعديل الإكسسوار بنجاح',
      },
      destroy: {
        success: 'تم حذف الإكسسوار بنجاح',
      },
      destroyAll: {
        success: 'تم حذف الإكسسوارات بنجاح',
      },
      edit: {
        title: 'تعديل الإكسسوار',
      },
      export: {
        title: 'يرجى إكمال الخطوات التالية لتصدير العنصر الخاص بك ',
        iterationNumber: 'عدد المنتجات المصدرة من هذا العنصر',
        barcode: 'الباركود لكل بند',
      },
      fields: {
        id: 'Id',
        'name': 'الإسم',
        itemName: 'إسم العنصر',
        description: 'الوصف',
        photos: 'الصورة',
        barcode: 'الباركود',
        features: 'المواصفات',
        brand: 'الماركة',
        quantity: 'الكمية',
        warrantyPeriod: 'مدة الضمان',

        accessoryName: 'إسم الإكسسوار',
        createbarcode: 'إنشاء باركود',

        createdAt: 'إنشاء في',
        updatedAt: 'تحديث في',
        createdAtRange: 'إنشاء في',
      },
      enumerators: {

      },
      new: {
        title: 'إضافة إكسسوار جديد',
      },
      view: {
        title: 'عرض الإكسسوار',
      },
    },

    export: {
      name: 'تصدير',
      label: 'التصدير',
      menu: 'التصدير',
      title: 'يرجى إكمال الخطوات التالية لتصدير العنصر الخاص بك ',
      iterationNumber: 'عدد المنتجات المصدرة من هذا العنصر',
      barcode: 'الباركود لكل بند',
      chooseItem: 'إختر عنصر',
      itemType: 'نوع العنصر',
      product: 'المنتجات',
      accessory: 'الإكسسوارات',
      sparePart: 'قطع الغيار',
      chooseYourItem: 'إختر العنصر',
      typeNumberHere: 'إكتب العدد هنا',

      list: {
        menu: 'التصدير',
        title: 'التصدير',
      },
      fields: {
        id: 'Id',
        itemId: 'Item ID',
        itemType: 'Item Type',
        status: 'Status',
        qrCode: 'QR Code',
        userId: 'User Id',
        activationDate: 'Activation Date',
        expirationDate: 'Warranty expiry date',
        
        iterationNumber: 'Number of item iteration',

        createdBy: 'إنشاء بواسطة',
        updatedBy: 'تحديث بواسطة',
        createdAt: 'إنشاء في',
        updatedAt: 'تحديث في',
        createdAtRange: 'إنشاء في',
      },
      enumerators: {
        'itemType': {
          'product': 'منتج',
          'sparePart': 'قطع غيار',
          'accessory': 'إكسسوار',
        },
        'status': {
          'inactive': 'غير نشط',
          'active': 'نشط',
          'expired': 'منتهي الصلاحية',
        },
      },
      // create: {
      //   success: 'Product saved successfully',
      // },
      // update: {
      //   success: 'Product saved successfully',
      // },
      // destroy: {
      //   success: 'Product deleted successfully',
      // },
      // destroyAll: {
      //   success: 'Product(s) deleted successfully',
      // },
      // edit: {
      //   title: 'Edit Product',
      // },
      // new: {
      //   title: 'Add New Product',
      // },
      // view: {
      //   title: 'View Product',
      // },
      // importer: {
      //   title: 'Import Products',
      //   fileName: 'product_import_template',
      //   hint: 'Files/Images columns must be the URLs of the files separated by space.',
      // },
    },


    exporter: {
      name: 'التصدير',
      label: 'التصدير',
      menu: 'التصدير',
      title1: 'يرجى إكمال الخطوات التالية لتصدير العنصر الخاص بك',
      iterationNumber: 'عدد مرات تكرار العنصر',
      barcode: 'الرموز الشريطية لكل عنصر',
      chooseItem: 'اختر منفذ بيع',
      chooseFromList: 'اختر من القائمة',
      exportFor: 'تصدير لـ',
      pleaseScanItems: 'يرجى مسح العناصر ضوئيًا',
      itemType: 'نوع العنصر',
      product: 'وحدة معالجة',
      accessory: 'إكسسوار',
      sparePart: 'قطع غيار',
      chooseYourItem: 'اختر البند الخاص بك',
      typeNumberHere: 'اكتب الرقم هنا',
      exporterFileName: 'file_export',
      sellerNotFound: 'الفرع أو الموزع غير موجود الرجاء اختيار منفذ البائع الصحيح',
      scanSerialNumberHere: 'امسح الرقم التسلسلي هنا',
      pleaseScanSerialNumber : 'يرجى مسح الرقم التسلسلي ضوئيًا',
      addItemToExportedTable: 'أضف عنصرًا إلى جدول التصدير',
      itemNotExist: 'هذا العنصر غير موجود',
      list: {
        menu: 'التصدير',
        title: 'التصدير',
      },
      form: {
        title: 'تصدير عناصر جديدة',
      },
      item: {
        title: 'الأصناف المصدرة',
      },
      new: {
        title: 'تصدير عناصر جديدة',
      },
      create: {
        success: 'تم حفظ العنصر بنجاح',
      },
      update: {
        success: 'تم تحديث العنصر بنجاح',
      },
      destroy: {
        success: 'تم حذف العنصر بنجاح',
      },
      destroyAll: {
        success: 'تم حذف العنصر (العناصر) بنجاح',
      },
      fields: {
        id: 'Id',
        itemId: 'Item ID',
        itemType: 'Item Type',
        status: 'Status',
        qrCode: 'QR Code',
        userId: 'User Id',
        activationDate: 'Activation Date',
        expirationDate: 'Warranty expiry date',
        iterationNumber: 'Number of item iteration',
        
        exportedBy: 'Exported By',
        exportedAt: 'Exported At',

        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'itemType': {
          'product': 'Product',
          'sparePart': 'Spare Part',
          'accessory': 'Accessory',
        },
        'status': {
          'inactive': 'Inactive',
          'active': 'Active',
          'expired': 'Expired',
        },
      },
    },

    importer: {
      name: 'إستيراد',
      label: 'إستيراد',
      menu: 'إستيراد',
      title1: 'يرجى إكمال الخطوات التالية لاستيراد العناصر الخاصة بك',
      title2: 'الرجاء إدخال المعلومات التالية في طابعتك',
      incorrectInputs: 'الرقم التسلسلي أو رمز الاستجابة السريعة غير صحيح',
      importedItems: 'الأصناف المستوردة',
      tryNow: ' جربه الآن',
      printing: 'طباعة',
      readyToPrint: 'جاهز للطباعة',
      printingNow: 'يطبع الآن',
      waitingSaveData: 'في انتظار حفظ البيانات',

      numberOfItems: 'عدد العناصر',
      barcode: 'الباركود لكل بند',
      chooseItem: 'إختر عنصر',
      itemType: 'نوع العنصر',
      product: 'المنتجات',
      accessory: 'الإكسسوارات',
      sparePart: 'قطع الغيار',
      chooseYourItem: 'إختر العنصر',
      typeNumberHere: 'إكتب العدد هنا',

      list: {
        menu: 'استيراد',
        title: 'استيراد',
      },
      form: {
        title: 'استيراد عناصر جديدة',
      },
      item: {
        title: 'العناصر',
      },
      new: {
        title: 'أضف عناصر جديدة',
      },
      create: {
        success: 'تم حفظ العنصر بنجاح',
      },
      update: {
        success: 'تم تحديث العنصر بنجاح',
      },
      destroy: {
        success: 'تم حذف العنصر بنجاح',
      },
      destroyAll: {
        success: 'تم حذف العنصر (العناصر) بنجاح',
      },
      fields: {
        id: 'Id',
        itemId: 'Item ID',
        itemType: 'نوع العنصر',
        status: 'حالة العنصر',
        warrantyStatus: 'حالة الضمان',
        qrCode: 'رمز الاستجابة السريعة',
        serialNumber: 'الرقم التسلسلي',
        userId: 'User Id',
        activationDate: 'تاريخ التفعيل',
        expirationDate: 'تاريخ إنتهاء الضمان',
        
        itemDetails: 'تفاصيل العنصر',
        itemName: 'اسم العنصر',
        brand: 'الماركة',
        photo: 'صورة',

        iterationNumber: 'عدد مرات تكرار العنصر',
        printingCountPerItem: 'عدد الطباعة لكل عنصر',

        exportedBy: 'تصدير بواسطة',
        exportedAt: 'صدر في',

        createdBy: 'إنشاء بواسطة',
        updatedBy: 'تحديث بواسطة',
        createdAt: 'إنشاء في',
        updatedAt: 'تحديث في',
        createdAtRange: 'إنشاء في',
      },
      placeholder: {
        printingCountPerItem: 'أدخل عدد مرات طباعة كل عنصر',
      },
      enumerators: {
        'itemType': {
          'product': 'منتج',
          'sparePart': 'قطع غيار',
          'accessory': 'إكسسوار',
        },
        'warrantyStatus': {
          'inactive': 'غير مفعل',
          'active': 'مفعل',
          'expired': 'منتهي الصلاحية',
        },
        'status': {
          'inStock': 'في المخزن',
          'exported': 'مصدرة',
        }
      },
    },
    
    seller: {
      name: 'تاجر',
      label: 'تاجر',
      menu: 'تاجر',
      represented :'ويمثله في هذا العقد: ',
      phoneEx: 'مثال : 1001234567',
      list: {
        menu: 'تاجر',
        title: 'تاجر',
      },
      createPin: {
        success: 'تم حفظ الرمز بنجاح',
      },
      create: {
        success: 'تم حفظ البيانات بنجاح',
      },
      update: {
        success: 'تم تحديث البيانات بنجاح',
      },
      destroy: {
        success: 'تم حذف البيانات بنجاح',
      },
      destroyAll: {
        success: 'تم حذف البيانات بنجاح',
      },
      edit: {
        title: 'تعديل البيانات',
        branch: 'تعديل الفرع',
        distributors: 'تعديل الموزع',
      },
      branch: {
        title: 'الفروع',
      },
      distributor: {
        title: 'الموزعين',
      },
      steps: {
        createMainData: 'إنشاء البيانات الاساسية',
        addManagerDetails: 'إضافة بيانات المدير',
        addLocation: 'إضافة الموقع',
      },
      fields: {
        id: 'ID',
        email: 'البريد الإلكتروني',
        name: 'الإسم رباعي',
        address: 'العنوان',
        telephone: 'رقم الهاتف',
        manager: 'المدير',
        managerName: 'اسم المدير',
        managerPhone: 'رقم هاتف المدير',
        isOnlineStore: 'هل لديك متجر على الإنترنت',
        websiteUrl: 'رابط الموقع',
        taxFacilityNumber: 'رقم المنشأة الضريبية',
        idNumber:'الرقم القومي',
        countryId: 'الدولة / البلد',
        cityId: 'المحافظة / المدينة',
        regionId: 'المنطقة',
        sellerType: 'النوع',
        distributorType: 'نوع التاجر / الموزع',
        branchName: 'اسم الفرع',
        distributorName: 'اسم الموزع', 
        retailer:'تاجر تجزئة',
        wholesaler: 'تاجر جملة',
        companyName:'إسم الشركة / المؤسسة / المحل',
        area:'مناطق التوزيع',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdBy: 'Created By',
        updatedBy: 'Updated By',
      },
      placeholder: {
        name: 'من فضلك أدخل الإسم رباعي',
        address: 'من فضلك أدخل العنوان',
        telephone: 'من فضلك أدخل رقم الهاتف',
        manager: 'من فضلك أدخل المدير',
        managerName: 'من فضلك أدخل اسم المدير',
        managerPhone: 'من فضلك أدخل رقم هاتف المدير',
        isOnlineStore: 'من فضلك أدخل هو متجر على الإنترنت',
        websiteUrl: 'من فضلك أدخل رابط الموقع',
        taxFacilityNumber: 'من فضلك أدخل رقم المنشأة الضريبية',
        idNumber:'من فضلك أدخل الرقم القومي',
        countryId: 'من فضلك أدخل الدولة / البلد',
        cityId: 'من فضلك أختر المحافظة / المدينة',
        city: 'من فضلك أدخل المحافظة / المدينة',
        email: 'من فضلك أدخل البريد الإلكتروني',
        regionId: 'من فضلك أختر المنطقة',
        sellerType: 'من فضلك أدخل النوع',
        distributorType: 'من فضلك أدخل نوع التاجر / الموزع',
        branchName: 'من فضلك أدخل اسم الفرع',
        distributorName: 'من فضلك أدخل اسم الموزع', 
        retailer:'من فضلك أدخل التجزئة',
        wholesaler:'من فضلك إختر تاجر الجملة التابع له',
        companyName:'من فضلك أدخل إسم الشركة / المؤسسة / المحل',
        area:'قم بإدخال منطقة التوزيع',
        // area:'قم بإدخال منطقة التوزيع ثم إضغط Enter',
        
      },
      error: {
        name: 'الاسم مطلوب',
        address: 'العنوان مطلوب',
        telephone: 'رقم الهاتف مطلوب',
        manager: 'المدير مطلوب',
        managerName: 'اسم المدير مطلوب',
        managerPhone: 'رقم هاتف المدير مطلوب',
        isOnlineStore: 'هو متجر على الإنترنت مطلوب',
        websiteUrl: 'رابط الموقع مطلوب',
        taxFacilityNumber: 'رقم المنشأة الضريبية مطلوب',
        idNumber:'الرقم القومي مطلوب',
        countryId: 'الدولة / البلد مطلوبة',
        cityId: 'المحافظة / المدينة مطلوبة',
        regionId: 'المنطقة مطلوبة',
        sellerType: 'النوع مطلوب',
        distributorType: 'نوع التاجر / الموزع مطلوب',
        branchName: 'اسم الفرع مطلوب',
        distributorName: 'اسم الموزع مطلوب',
        retailer:'تاجر التجزئة مطلوب',
        wholesaler:'تاجر الجملة مطلوب',
        companyName:'إسم الشركة/المؤسسة/المحل مطلوب',
        area:'مناطق التوزيع مطلوبة',
      },
      enumerators: {
        'sellerType': {
          "branch": 'فرع',
          "distributor": 'موزع',
        },
        'distributorType': {
          "retailer": 'تاجر تجزئة',
          "wholesaler": 'تاجر جملة',
        },
      },
      new: {
        title: 'إضافة منفذ جديد',
        branch: 'اضافة فرع جديد',
        distributors: 'اضافة موزع جديد',
      },
      view: {
        title: 'عرض بيانات التاجر',
        branch: 'عرض الفرع',
        distributors: 'عرض بيانات الموزع',
      },
      importer: {
        title: 'Import Sellers',
        fileName: 'seller_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    
    city: {
      name: 'المحافظات / المدن',
      label: 'المحافظات / المدن',
      menu: 'المحافظات / المدن',
      list: {
        menu: 'المحافظات / المدن',
        title: 'المحافظات / المدن',
      },
      new: {
        title: 'إضافة مدينة جديدة',
      },
      create: {
        success: 'تم حفظ المدينة بنجاح',
      },
      update: {
        success: 'تم تحديث المدينة بنجاح',
      },
      destroy: {
        success: 'تم حذف المدينة بنجاح',
      },
      destroyAll: {
        success: 'تم حذف المدن بنجاح',
      },
      fields: {
        id: 'الرقم التعريفي',
        name: 'اسم المدينة',
        nameEn: 'اسم المدينة انجليزي',
        nameAr: 'اسم المدينة عربي',
        regions: 'المناطق',

        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created at',
      },
      placeholder: {
        nameEn: 'أدخل اسم المدينة باللغة الإنجليزية',
        nameAr: 'أدخل اسم المدينة باللغة العربية',
      },
    },

    region: {
      name: 'المناطق',
      label: 'المناطق',
      menu: 'المناطق',
      list: {
        menu: 'المناطق',
        title: 'المناطق',
      },
      new: {
        title: 'اضافة منطقة جديدة',
      },
      create: {
        success: 'تم حفظ المنطقة بنجاح',
      },
      update: {
        success: 'تم تحديث المنطقة بنجاح',
      },
      destroy: {
        success: 'تم حذف المنطقة بنجاح',
      },
      destroyAll: {
        success: 'تم حذف المناطق بنجاح',
      },
      fields: {
        id: 'الرقم التعريفي',
        name: 'اسم المنطقة',
        nameEn: 'اسم المنطقة باللغة الانجليزية',
        nameAr: 'اسم المنطقة باللغة العربية',
        regions: 'المناطق',
        city: 'المدينة',

        createdBy: 'Created By',
        updatedBy: 'Updated By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        createdAtRange: 'Created at',
      },
      placeholder: {
        nameEn: 'أدخل اسم المدينة باللغة الإنجليزية',
        nameAr: 'أدخل اسم المدينة باللغة العربية',
        city: 'أختر المدينة',
      },
    },
    
    complaint: {
      name: 'الشكاوى',
      placeholder: 'أبحث برقم الشكوى',
      noComplaint:'لا توجد شكاوى',
      messagePlaceholder:'أكتب رسالتك هنا'
    }
  },

  auth: {
    error: {
      emailRequired: 'البريد الالكتروني مطلوب',
      phoneNumberRequired: 'رقم الهاتف مطلوب',
      invalidPhoneNumber:'رقم الهاتف غير صالح',
      passwordRequired: 'كلمة المرور مطلوبة',
      confirmRequired: 'تأكيد كلمة المرور مطلوب',
      confirmNotMatched: 'تأكيد كلمة المرور غير متطابقة',
      phoneRequired: 'رقم الهاتف مطلوب',
      codeRequired: 'Verification Code is required',
      idNumberRequired: 'Id Number is required',
      PhoneMustBe_11_Digits: 'The Phone number must be 9 digits',
      firstNameRequired: 'First Name is required',
      lastNameRequired: 'Last Name is required',
      idNumberShouldOnlyBeNumbers: 'The ID number should only be numbers',
      verificationCodeShouldOnlyBeNumbers: 'The Verification Code should only be numbers',
      nameRequired:'الإسم مطلوب',
      telephoneRequired:'رقم الهاتف',
      taxRequired:'رقم التسهيل الضريبي مطلوب',
      retailerRequired:'مطلوب بائع تجزئة',
    },
    splash: {
      title: '',
      subTitle: '',
      hint: '',
      letsGetStarted: 'لنبدأ',
    },
    profile: {
      title: 'الملف الشخصي',
      edit: 'تعديل الملف الشخصي',
      success: 'تم تحديث الملف الشخصي بنجاح',
    },
    fields: {
      email: 'عنوان البريد الإلكتروني',
      confirm: 'تأكيد كلمة المرور',
      phoneNumber: 'رقم الهاتف',
      password: 'كلمه المرور',
      pinCode:'الرقم السري',

      name:'الإسم',
      telephone:'رقم الهاتف',
      tax:'رقم التسهيل الضريبي',
      sellerType:'نوع البائع',
      retailer:'اختر بائع تجزئة',
      isOnline:'متجر على الإنترنت'
    },
    placeholder: {
      email: 'أدخل عنوان البريد الإلكتروني هنا',
      confirm: 'أدخل تأكيد كلمة المرور هنا',
      phoneNumber: 'رقم الهاتف',
      password: 'أدخل كلمة المرور هنا',
    },
    sellerEnum:{
      wholesaler:'تاجر جملة',
      retailer:'تاجر تجزئة'
    },
    resend:'إعادة أرسال الرمز',
    signature:'التوقيع',
    yourContract:'العقد الخاص بك',
    contractAcknowledgment : 'أقر انا {0} بأنني قرأت ووافقت على الشروط والبنود المذكورة بالعقد',
    contractPlaceholder:'يرجى قراءة العقد بعناية والاختيار بين تأكيد أو تجاهل العقد',
    developedByRunProf: 'Developed by RunProf',
    createMainData:'إنشاء البيانات الرئيسية',
    addLocation:'أضف العنوان',
    addManagerDetails:'بيانات المدير المسؤول',
    continue:'إستمرار',
    ignore:'تجاهل',
    welcomeTo: 'مرحبا بك في ',
    puricomEgypt: 'بيوريكم ايجيبت ',
    message: 'نظام احترافي لمعالجة المياه',
    sellersDashboard:'لوحة تحكم الموزعين المعتمدين',
    site:'www.puricomegypt.com',
    verifyAccount: 'تحقق من حسابك',
    createdSuccessfully: 'تم إنشاء حسابك الجديد بنجاح',
    createdNewAccount: 'إنشاء حسابك الجديد',
    registrationSteps: 'Registration Steps',
    DoNotHaveAccount: 'ليس لديك حساب؟ ',
    createAnAccount: 'إنشاء حساب',
    rememberMe: 'تذكرني',
    forgotPassword: 'نسيت كلمة المرور؟',
    forgotPasswordTitle: 'هل نسيت كلمة المرور',
    signin: 'تسجيل الدخول',
    signup: 'إنشاء حساب',
    signout: 'تسجيل الخروج',
    login: 'تسجيل الدخول',
    
    verify:{
      verify:'تأكيد الحساب',
      message:'من فضلك أدخل الكود الذي تم أرساله اليك'
    },
    alreadyHaveAnAccount: ' لديك حساب بالفعل؟ ',
    signinWithAnotherAccount: 'تسجيل الدخول باستخدام حساب آخر',
    registration:{
      registration:'التسجيل',
      message:'الرجاء إدخال الرقم السري الخاص بك لإستكمال التعاقد',
      message2:'الرجاء إدخال البيانات الخاصة بك لإستكمال التعاقد',
      enterYourPhoneNumper:'أدخل رقم هاتفك',
      enterYourPhoneNumperPlaceholder:'من فضلك أدخل رقم هاتفك'
    },
    signUp: {
      sign: 'إنشاء ',
      up: 'حساب',
      message: `يرجى إدخال عنوان بريدك الإلكتروني وكلمة المرور لإنشاء حساب جديد`,
    },
    logIn: {
      log: 'تسجيل ',
      in: 'دخول',
      message: `يرجى إدخال عنوان بريدك الإلكتروني وكلمة المرور لتسجيل الدخول إلى حسابك`,
    },
    emailUnverified: {
      title: 'تأكيد عنوان البريد الإلكتروني',
      message: `يرجى التأكد من صحة البريد الإلكتروني الخاص بك على <strong style="color: #00B2E3;">{0}</strong> للمتابعة.`,
      submit: `إعادة إرسال البريد الإلكتروني للتحقق`,
      verify: 'التحقق من عنوان البريد الإلكتروني',
      confirm: 'تأكيد ',
      emailAddress: 'عنوان البريد الإلكتروني',
    },
    forgetPassword: {
      title: 'نسيت كلمة المرور؟',
      message: `أدخل عنوان بريدك الإلكتروني الذي تستخدمه لحسابك، وسوف نرسل لك رابط إعادة تعيين كلمة المرور`,
      submit: `Request Reset link`,
      placeholder: 'أدخل عنوان بريدك الالكتروني هنا',
      forget: 'إعادة تعيين ',
      password: 'كلمة المرور',
      resendLink: 'إعادة إرسال الرابط',
    },
    emptyPermissions: {
      title: 'Pending request',
      account: 'إذن ',
      permission: 'حساب',
      message: `ليس لديك صلاحيات حتى الآن. انتظر حتى يمنحك المسؤول صلاحية`,
    },
    passwordResetEmail: {
      message: 'إرسال بريد إلكتروني لإعادة تعيين كلمة المرور',
      error: `لم يتم التعرف على البريد الإلكتروني`,
    },
    passwordReset: {
      message: 'إعادة تعيين كلمة المرور',
    },
    emailAddressVerificationEmail: {
      error: `لم يتم التعرف على البريد الإلكتروني`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    customerEditor: {
      label: 'Customer Editor',
      description: 'Edit access to Customers',
    },
    customerViewer: {
      label: 'Customer Viewer',
      description: 'View access to Customers',
    },
    productEditor: {
      label: 'Product Editor',
      description: 'Edit access to Products',
    },
    productViewer: {
      label: 'Product Viewer',
      description: 'View access to Products',
    },
    orderEditor: {
      label: 'Order Editor',
      description: 'Edit access to Orders',
    },
    orderViewer: {
      label: 'Order Viewer',
      description: 'View access to Orders',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'الصورة الشخصية',
      email: 'البريد الإلكترونى',
      emails: 'Email(s)',
      fullName: 'الإسم',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'الحالة',
      disabled: 'Disabled',
      phoneNumber: 'الهاتف',
      role: 'الصلاحية',
      createdAt: 'إنشاء في',
      updatedAt: 'تحديث في',
      roleUser: 'Role/User',
      roles: 'الصلاحيات',
      createdAtRange: 'إنشاء في',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
      phone: 'رقم الهاتف غير صالح',
      idNumber: 'الرقم القومي غير صالح',
      taxFacilityNumber:'الرقم المنشأة الضريبية غير صالح'
    },
  },
  home: {
    menu: 'الرئيسية',
    contract:'العقد',
    confirmed:'لقد تم قبول طلبك بنجاح',
    pending:'طلبك تحت المراجعة',
    renewalPending:'طلب التجديد تحت المراجعة',
    banner:'أنت الآن موزع لبيوريكم مصر',
    ending:' متبقي لك {0} يوم على انتهاء العقد',
    ended:'لقد أنتهى عقدك يرجى الاتصال بنا للتجديد',
    canceled:'لقد تم إلغاء التعاقد معك من قبل الشركة',
    renew:'تجديد',
    bannerRetailer:'أنت الآن موزع لبيوريكم مصر',
    message: `This page uses fake data for demonstration purposes only. You can edit it at seller-frontend/src/modules/home/components/home-page.vue.`,
    newFeaturesAreComing:'الميزات الجديدة قادمة',
    printTheContract:'طباعة العقد',
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },

  auditLog: {
    menu: 'سجلات التدقيق',
    title: 'سجلات التدقيق',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'افصل بين الكيانات المتعددة باستخدام الحرف الفاصلة.',
    fields: {
      id: 'Id',
      timestampRange: 'فترة',
      entityName: 'كيان',
      entityNames: 'الكيانات',
      entityId: 'معرف الكيان',
      action: 'الإجراء',
      values: 'القيم',
      timestamp: 'تاريخ',
      createdByEmail: 'البريد الالكتروني للمستخدم',
    },
  },

  settings: {
    title: 'الإعدادات',
    menu: 'الإعدادات',
    save: {
      success: 'تم حفظ الإعدادات بنجاح. ستتم إعادة تحميل الصفحة في {0} ثانية لتصبح التغييرات نافذة المفعول.',
    },
    fields: {
      theme: 'Theme',
      barcodeTitle: 'عنوان الباركود',
    },
    palceholder: {
      barcodeTitle: 'اكتب عنوان الباركود هنا',
    },
    colors: {
      default: 'الإفتراضي',
      cyan: 'ازرق سماوي',
      'geek-blue': 'المهوس الأزرق',
      'astronaut-blue': 'Astronaut Blue',
      gold: 'ذهبي',
      lime: 'جيري',
      magenta: 'أرجواني',
      orange: 'برتقالي',
      'polar-green': 'Polar Green',
      purple: 'بنفسجي',
      red: 'احمر',
      volcano: 'بركاني',
      yellow: 'اصفر',
    },
  },

  errors: {
    required: 'هذا الحقل مطلوب',
    minValue: 'الحد الأدنى للقيمة هو {0}',
    maxValue: 'القيمة القصوى هي {0}',
    maxCharCount: 'الرجاء استخدام الحد الأقصى لعدد الأحرف {0}',
    typeRequired: 'النوع مطلوب',
    languageRequired: 'اللغة مطلوبة',
    destinationRequired: 'الجهة مطلوبة',
    purposeRequired: 'هذا الحقل مطلوب',
    workAtRequired: 'هذا الحقل مطلوب',
    wifeNameRequired: 'الإسم مطلوب',
    dateRequired: 'التاريخ مطلوب',
    timeRequired: 'الوقت مطلوب',
    hospitalNameRequired: 'إسم المستشفي مطلوب',
    nameRequired: 'الإسم مطلوب',
    workPositionRequired: 'هذا الحقل مطلوب',
    signatureRequired: 'التوقيع مطلوب',
    birthDateRequired: 'تاريخ الميلاد مطلوب',
    relativeRelationRequired: 'صلة القرابة مطلوبة',
    placeOfBusinessRequired: 'مكان العمل مطلوب',
    placeOfResidenceRequired: 'مكان الإقامة مطلوب',
    buildingRequired: 'المبني مطلوب',
    floorRequired: 'الدور مطلوب',
    accountTypeRequired: 'نوع الحساب مطلوب',
    managerTypeRequired: 'الإدارة مطلوبة',
    invalidPhoneNumber: 'رقم الهاتف غير صالح',
    invalidNoteField: 'حقل ملاحظة غير صالح',
    invalidReasonOfPermission: 'سبب غير صالح لحقل الإذن',
    departmentRequired: 'القسم مطلوب',
    subDepartmentRequired: 'القسم الفرعي مطلوب',
    jobTitleRequired: 'المسمي الوظيفي مطلوب',
    employeeRequired: 'الموظف مطلوب',
    employeesRequired: 'الموظف مطلوب',

    recordIsFound: 'This record is already exist with the same barcode',

    backToHome: 'الرجوع للرئيسية',
    403: `عذرًا ، لا يمكنك الوصول إلى هذه الصفحة`,
    404: 'عذرًا ، الصفحة التي قمت بزيارتها غير موجودة',
    500: 'عذرًا ، يبلغ الخادم عن وجود خطأ',
    forbidden: {
      message: 'ممنوع',
    },
    validation: {
      message: 'حدث خطأ',
    },
    defaultErrorMessage: 'عفوًا ، حدث خطأ',
  },

  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: ' غير صالح ${path}',
      required: ' مطلوب ${path}',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({
        path,
        type,
        value,
        originalValue
      }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },

  /* eslint-disable */
  fileUploader: {
    upload: 'رفع',
    attach: 'إرفاق ملف',
    image: 'يجب عليك تحميل صورة',
    size: 'الملف كبير جدا. أقصى حجم مسموح به هو {0}',
    formats: `تنسيق غير صالح. يجب أن يكون '{0}'.`,
    picture: 'أرفق صورة'

  },

  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'جار التحميل...',
  },

  imagesViewer: {
    noImage: 'لا توجد صورة',
  },

  firebaseErrors: {
    'auth/user-disabled': 'تم تعطيل حسابك',
    'auth/user-not-found': `عذرًا ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك`,
    'auth/wrong-password': `عذرًا ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك`,
    'auth/weak-password': 'كلمة المرور هذه ضعيفة جدًا',
    'auth/email-already-in-use': 'البريد الالكتروني قيد الاستخدام بالفعل',
    'auth/invalid-email': 'يرجى تقديم عنوان بريد إلكتروني صالح',
    'auth/account-exists-with-different-credential': 'البريد الإلكتروني مستخدم بالفعل لطريقة مصادقة مختلفة.',
    'auth/credential-already-in-use': 'بيانات الاعتماد قيد الاستخدام بالفعل',
    'auth/invalid-phone-number': 'رقم الهاتف غير صالح (قصير جدًا)',
    'auth/invalid-verification-code': 'رمز التحقق غير صالح',
    toManyTries:'لقد تم حظرك نتيجة لكثرة المحاولات'
  },
};

export default ar;