import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
import { SettingsService } from '@/modules/settings/settings-service';
import ProgressBar from '@/shared/progress-bar/progress-bar';
// import { i18n } from '@/i18n';
import i18n from './vueI18n'
import PortalVue from 'portal-vue';
import './quasar'
import VueSignature from "vue-signature-pad";
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import VieOtpInput from "@bachdgvn/vue-otp-input";

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// Custom Styles
import './scss/index.scss'


import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: 'test',
  specs: [
    'fullscreen=yes',
    'titlebar=no',
    'scrollbars=no'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'https://seller-puricom.web.app/styles/css/pdf.css',
    'http://localhost:8082/styles/css/pdf.css',
    'https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap',
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900%7CMaterial+Icons',
    'https://cdn.jsdelivr.net/npm/quasar@1.14.5/dist/quasar.min.css',
    'https://cdnjs.cloudflare.com/ajax/libs/angular-material-icons/0.7.1/angular-material-icons.min.js',
    'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
    'https://cdn.jsdelivr.net/npm/vue@^2.0.0/dist/vue.min.js',
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900%7CMaterial+Icons%7CMaterial+Icons+Outlined%7CMaterial+Icons+Round%7CMaterial+Icons+Sharp',
    'https://cdn.jsdelivr.net/npm/@mdi/font@^5.0.0/css/materialdesignicons.min.css',
    'https://use.fontawesome.com/releases/v5.0.13/css/all.css',
    'https://cdn.jsdelivr.net/npm/ionicons@^4.0.0/dist/css/ionicons.min.css',
    'https://cdn.jsdelivr.net/npm/eva-icons@^1.0.0/style/eva-icons.css',
    'https://themify.me/wp-content/themes/themify-v32/themify-icons/themify-icons.css',
    'https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css',
    'https://cdn.jsdelivr.net/npm/quasar@1.14.5/dist/quasar.min.css',
    'https://cdn.jsdelivr.net/npm/vue@^2.0.0/dist/vue.js',
    'https://cdn.jsdelivr.net/npm/quasar@1.14.5/dist/quasar.umd.js',
    'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fprint-style.css?alt=media&token=0cdb873c-d0bc-4c0a-b64a-d142bbb2aeed'
  ]
}

// import { firestorePlugin } from 'vuefire'

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueSignature);
Vue.component('vie-otp-input', VieOtpInput);



(async function() {
  // document.title = i18n('app.title');
  ProgressBar.start();
  SettingsService.fetchAndApply();

  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();
 
// ======================================= New Installer ======================================= //
//#region [ New Installer ]
  // Vue.use(firestorePlugin)
  Vue.use(Vuesax)
//#endregion
// ============================================================================================= //

  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    render: (h) => h(app),
    i18n
  }).$mount('#app');
})();

