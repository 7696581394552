import { storeAsync } from '@/app-module';

export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.meta || !to.meta.emailAlreadyVerified) {
      next();
      return;
    }

    const store = storeAsync();
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      await store.dispatch('auth/doWaitUntilInit');
    }
    // await store.dispatch('auth/doWaitUntilInit');
    
    if (
      store.getters['auth/signedIn'] &&
      store.getters['auth/currentUser'].contractConfirmed
    ) {
      next('/');
    } else {
      next();
    }
  },
};
