//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuItem from '@/modules/layout/components/menu-item.vue';

import { mapGetters, mapActions } from 'vuex';
import { SettingsPermissions } from '@/modules/settings/settings-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { IamPermissions } from '@/modules/iam/iam-permissions';


export default {
  name: 'app-menu',

  components: {
    [MenuItem.name]: MenuItem,
  },

  data() {
    return {
      active: '',
      // path: this.routePath(), 
    };
  },
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToContract(){
      return this.currentUser.status
    },
    hasPermissionToSettings() {
      return new SettingsPermissions(this.currentUser).edit;
    },

    hasPermissionToAuditLog() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToIam() {
      return new IamPermissions(this.currentUser).read;
    },
    asideWidth() {
      if (!this.collapsed) {
        return '200px';
      }
      return '0px';
    },
    isActive() {
      const routePath = this.$route.path;
      const active = routePath === this.path || routePath.startsWith(this.path + '/');
      return active
    },
    routePath() {
      return this.$route.path;
      // return this.$router.currentRoute.fullPath;
    },
    openDatabaseGroup() {
      return this.active == 'product' || this.active == 'spare-part' || this.active == 'accessory' || this.active == 'iam'
    },
    openUserGroup() {
      return this.active == 'admin' || this.active == 'client'
    },
  },
  created() {
    const routePath = this.$route.path;
    const firstPart = routePath.split('/')[1]
    if (firstPart == '') {
      this.active = 'home'
    } else {
      this.active = firstPart
    }
    //  console.log('Active Path = ', this.active)
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),

    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },
    // routePath() {
    //   return this.$router.currentRoute.fullPath;
    //   // return window.location.pathname
    // },
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active =
        routePath === path ||
        routePath.startsWith(path + '/');

      return {
        active,
      };
    },
    routeTo(path) {
       console.log('Path To => ', path)
    },

  },
  watch: {
    routePath() {
      const routePath = this.$route.path;
      const firstPart = routePath.split('/')[1]
      //  console.log(firstPart)
      if (firstPart == '') {
        this.active = 'home'
      } else {
        this.active = firstPart
      }
      //  console.log('Active Path = ', this.active)
    }
  },
};
