import { storeAsync } from '@/app-module';

export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.meta || !to.meta.auth) {
      next();
      return;
    }

    const store = storeAsync();
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      await store.dispatch('auth/doWaitUntilInit');
    }
    // await store.dispatch('auth/doWaitUntilInit');
    
    if (!store.getters['auth/signedIn']) {
      // next({ path: '/auth/signin' });
      next({ path: '/auth' });
      return;
    }
    if (
      to.path !== '/auth/details' &&
      !store.getters['auth/currentUser'].roles.length
    ) {
      next({ path: '/auth/details' });
      return;
    }
    if (
      to.path === '/auth/edit-profile' &&
      store.getters['auth/currentUser'].phoneNumber &&
      store.getters['auth/currentUser'].roles.length
    ) {
      next();
      return;
    }

    if (
      to.path !== '/contract' && 
      store.getters['auth/currentUser'].phoneNumber &&
      store.getters['auth/currentUser'].roles.length &&
      // !store.getters['auth/currentUser'].contractConfirmed &&
      store.getters['auth/currentUser'].status !== 'confirmed'
    ) {
      next({ path: '/contract' });
      return;
    }

    // if (
    //   to.path !== '/auth/empty-permissions' && store.getters['auth/currentUser'].phoneNumber &&
    //   store.getters['auth/currentUser'].contractConfirmed &&
    //   !store.getters['auth/roles'].length
    // ) {
    //   next({ path: '/auth/empty-permissions' });
    //   return;
    // }

    next();
  },
};
