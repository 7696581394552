import sellerListStore from '@/modules/seller/seller-list-store';
import sellerViewStore from '@/modules/seller/seller-view-store';
import sellerImporterStore from '@/modules/seller/seller-importer-store';
import sellerFormStore from '@/modules/seller/seller-form-store';
import sellerDestroyStore from '@/modules/seller/seller-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: sellerDestroyStore,
    form: sellerFormStore,
    list: sellerListStore,
    view: sellerViewStore,
    importer: sellerImporterStore,
  },
};
