//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import Message from '@/shared/message/message';
import firebase from 'firebase'
import 'firebase/firestore'
export default {
  name: 'app-signautre-item-page',
  props: ['id', 'loading', 'storePath'],

  data() {
    return {
      pinCode:'',
      options: {
      penColor: "#00f"
    }
    };
  },
  computed: {
    ...mapGetters({settings:'settings/settings',currentUser:'auth/currentUser'})
  },
  created(){
    // this.doFind()
  },
  methods: {
    ...mapActions({doFind:'settings/doFind',doSave:'settings/doSave'}),
    i18n(key, args) {
      return this.$t(key, args);
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    async doSubmit() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(!isEmpty){
        let docs = (await firebase.firestore().collection('tempSeller').where('phoneNumber','==',this.currentUser.phoneNumber).get()).docs
        if(docs.length){
          docs[0].ref.update({signature:data})
        }
        this.$emit('submit',data)
        // this.settings.signature = data
        // this.doSave(this.settings)
      }
    },
  },
};
