import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
// import DecimalRangeField from '@/shared/fields/decimal-range-field';
// import DecimalField from '@/shared/fields/decimal-field';
// import ImageField from '@/shared/fields/image-field';
// import ImagesField from '@/shared/fields/images-field';
// import IntegerField from '@/shared/fields/integer-field';
// import StringArrayField from '@/shared/fields/string-array-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';
import StringArrayField from '@/shared/fields/string-array-field';


function label(name) {
  return i18n(`entities.seller.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.seller.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  address: new StringField('address', label('address'), {}),
  telephone: new StringField('telephone', label('telephone'), {}),
  
  manager: new JsonField('manager', label('manager')),
  managerName: new StringField('managerName', label('managerName'), {}),
  managerPhone: new StringField('managerPhone', label('managerPhone'), {}),
  company: new StringField('company',label('company')),
  wholesalerId: new StringField('wholesalerId',label('wholesalerId')),
  area: new StringArrayField('area',label('area')),
  isOnlineStore: new BooleanField('isOnlineStore', label('isOnlineStore'), {
      noLabel: i18n('common.no'),
      yesLabel: i18n('common.yes'),
    },
  ),
  websiteUrl: new StringField('websiteUrl', label('websiteUrl'), {}),
  taxFacilityNumber: new StringField('taxFacilityNumber', label('taxFacilityNumber'), {}),

  countryId: new StringField('countryId', label('countryId'), {}),
  cityId: new StringField('cityId', label('cityId'), {}),
  regionId: new StringField('regionId', label('regionId'), {}),
  sellerType:  new EnumeratorField('sellerType', label('sellerType'), [
    { value: 'branch', label: enumeratorLabel('sellerType', 'branch') },
    { value: 'distributor', label: enumeratorLabel('sellerType', 'distributor') },
  ],{}),
  distributorType:  new EnumeratorField('distributorType', label('distributorType'), [
    { value: 'retailer', label: enumeratorLabel('distributorType', 'retailer') },
    { value: 'wholesaler', label: enumeratorLabel('distributorType', 'wholesaler') },
  ],{}),

  city: new JsonField('city', label('cityId'), {}),
  region: new JsonField('region', label('regionId'), {}),
  
  contractConfirmed:new BooleanField('contractConfirmed',label('contractConfirmed')),
  pinCode:new StringField('pinCode',label('pinCode')),
  signature:new StringField('signature',label('signature')),
  contractDate: new StringField('contractDate',label('contractDate')),
  contractEndDate: new StringField('contractEndDate',label('contractEndDate')),
  
  retailerId: new StringField('retailerId',label('retailer')),
  idNumber: new StringField('idNumber',label('idNumber')),
  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy',label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt',label('updatedAt')), 
  createdAtRange: new DateTimeRangeField( 'createdAtRange', label('createdAtRange')),
};

export class SellerModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
