import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=a5b2cb3a&scoped=true&"
import script from "./menu.vue?vue&type=script&lang=js&"
export * from "./menu.vue?vue&type=script&lang=js&"
import style0 from "./menu.vue?vue&type=style&index=0&id=a5b2cb3a&lang=scss&scoped=true&"
import style1 from "./menu.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5b2cb3a",
  null
  
)

export default component.exports
import {QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon})
