//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-view-item-page',

  props: ['record'],

  computed: {
    avatar() {
      // if (this.record.photos.length) {
      //   return this.record.photos[0].publicUrl
      // }
      if (this.record.photo) {
        return this.record.photo.publicUrl
      }
      return '/images/no-image.svg'
    },
    features() {
      return this.record.features.split(".").filter(el => el != '')
    },
  },
  methods: {
    doClose() {
      this.$emit('close')
    }
  },
};
